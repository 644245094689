const ROLES = [
  {
    id: 1,
    name: 'client',
  },
  {
    id: 2,
    name: 'coordinator',
  },
  {
    id: 4,
    name: 'monitoringChief',
  },
  {
    id: 5,
    name: 'manager',
  },
  {
    id: 6,
    name: 'operationsChief',
  },
  {
    id: 7,
    name: 'humanCapital',
  },
  {
    id: 8,
    name: 'outbound',
  },
  {
    id: 9,
    name: 'outboundCoordinator',
  },
  {
    id: 10,
    name: 'clientDestination',
  },
];
const ROLES_NAME = [
  'Cliente',
  'Coordinador',
  'Operador',
  'Jefe de monitoreo',
  'Gerente',
  'Jefe de operaciones',
  'Capital humano',
  'Outbound',
  'Coordinador Externo',
  'Destinatario',
];
export {
  ROLES,
  ROLES_NAME,
};
