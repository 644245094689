import axiosClient from '@/core/api/api.client';
import { API_URL } from '@/core/constants/urls.constants';
import Evidence from '@/core/types/Evidences';

const EvidencesService = {
  getEvidences: async (tripId: number) => axiosClient.get<Evidence[]>(`${API_URL}/evidences/trip/${tripId}`),
  getEvidencesByDestination: async (tripId: number, destinationId: number) => axiosClient.get<Evidence[]>(`${API_URL}/evidences/trip/${tripId}/destination/${destinationId}`),
};

export default EvidencesService;
