import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "justify-center d-flex px-10 py-5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "px-5" }
const _hoisted_4 = { class: "body--medium--medium color-text" }
const _hoisted_5 = { class: "px-5 my-5" }
const _hoisted_6 = { class: "body--light--large color-text" }
const _hoisted_7 = { class: "img-visualizer overflow-auto" }
const _hoisted_8 = {
  class: "justify-center d-flex px-10 py-5 color-text",
  style: {"margin-top":"50px"}
}
const _hoisted_9 = ["src"]

import { ref } from 'vue';
import { Incident } from '@/core/types/Incidents';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewDetailIncident',
  props: {
    incident: {},
    onClick: { type: Function }
  },
  setup(__props: any) {



const show = ref(false);
const expandImg = ref(false);

const setExpandImg = () => {
  expandImg.value = true;
};


return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "mt-10" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "body--bold--xlarge color-text" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Incidentes")
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_v_progress_circular, {
            style: {"margin-top":"100px","margin-bottom":"100px"},
            color: "primary",
            indeterminate: "",
            size: 50,
            width: 8
          }, null, 512), [
            [_vShow, !show.value]
          ]),
          _withDirectives(_createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (setExpandImg()), ["stop"])),
            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (setExpandImg())),
            onLoad: _cache[2] || (_cache[2] = ($event: any) => (show.value=true)),
            src: _ctx.incident?.image,
            alt: "incidente",
            style: {"width":"80%","height":"auto"}
          }, null, 40, _hoisted_2), [
            [_vShow, show.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString((_ctx.incident?.date_incident.split(' ')[0])?.split('-').reverse().join('/') || '-'), 1),
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "body--medium--xlarge color-text" }, "Unidad parada por incidente", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "body--medium--medium color-text" }, "Observaciones:", -1)),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.incident?.description || '-'), 1)
        ]),
        _createVNode(_component_v_card_actions, { class: "d-flex justify-end my-2 pr-5" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "body--medium--medium",
              color: "primary",
              variant: "elevated",
              onClick: _ctx.onClick
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Cerrar")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: expandImg.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((expandImg).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_btn, {
            class: "button-close",
            color: "white",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (expandImg.value=false)),
            variant: "text",
            icon: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "40" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_v_progress_circular, {
              style: {"margin-top":"100px","margin-bottom":"100px"},
              color: "primary",
              indeterminate: "",
              size: 50,
              width: 8
            }, null, 512), [
              [_vShow, !show.value]
            ]),
            _withDirectives(_createElementVNode("img", {
              class: "img-view",
              onLoad: _cache[4] || (_cache[4] = ($event: any) => (show.value=true)),
              src: _ctx.incident?.image,
              alt: "incidente"
            }, null, 40, _hoisted_9), [
              [_vShow, show.value]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})