import { createApp } from 'vue';

import * as Sentry from '@sentry/vue';

import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VDataTable, VDataTableFooter } from 'vuetify/labs/VDataTable';
import { VDatePicker } from 'vuetify/labs/VDatePicker';
import { createVuetify } from 'vuetify';
// eslint-disable-next-line import/no-extraneous-dependencies
import DayJSAdapter from '@date-io/dayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import es from 'dayjs/locale/es';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DesignationLayout from '@/core/layouts/DesignationLayout.vue';
import App from './App.vue';
import router from './core/router';

import './scss/styles.scss';
import myCustomTheme from './theme';
import '@mdi/font/css/materialdesignicons.css';
import 'v-calendar/style.css';
// Vuetify

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable,
    VDataTableFooter,
    VDatePicker,
  },
  directives,
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme,
    },
  },
  date: {
    adapter: DayJSAdapter,
    locale: {
      es,
    },
  },
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://aeacd47f62204651ba69e5b6d57d60aa@o1145527.ingest.sentry.io/4505194337730560',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.use(vuetify);
app.component('VueDatePicker', VueDatePicker);
app.component('AppLayout', DesignationLayout);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCEE_wyJsCl__hC9BQr7GYqUik0ZFdEg4w',
  },
});
app.mount('#app');
