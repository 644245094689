<script setup lang='ts'>
import { ref } from 'vue';
import { Incident } from '@/core/types/Incidents';

defineProps<{
  incident?: Incident,
  onClick:() => void
}>();

const show = ref(false);
const expandImg = ref(false);

const setExpandImg = () => {
  expandImg.value = true;
};

</script>

<template>
  <v-card class='mt-10'>
    <v-card-title class='body--bold--xlarge color-text'>Incidentes</v-card-title>
    <div class='justify-center d-flex px-10 py-5'>
      <v-progress-circular v-show='!show' style='margin-top: 100px; margin-bottom: 100px;' color='primary' indeterminate :size='50' :width='8'></v-progress-circular>
      <img @click.stop='setExpandImg()' @keydown='setExpandImg()' v-show="show" @load="show=true" :src='incident?.image' alt='incidente' style='width: 80%; height: auto'>
    </div>
    <div class='px-5'>
      <p class='body--medium--medium color-text'>{{(incident?.date_incident.split(' ')[0])?.split('-').reverse().join('/') || '-'}}</p>
      <p class='body--medium--xlarge color-text'>Unidad parada por incidente</p>
    </div>
    <div class='px-5 my-5'>
      <p class='body--medium--medium color-text'>Observaciones:</p>
      <p class='body--light--large color-text'>{{incident?.description || '-'}}</p>
    </div>
    <v-card-actions class='d-flex justify-end my-2 pr-5'>
      <v-btn class='body--medium--medium' color='primary' variant='elevated' @click='onClick'>Cerrar</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model='expandImg'>
    <div class='img-visualizer overflow-auto'>
      <v-btn class='button-close' color='white' @click='expandImg=false' variant='text' icon>
        <v-icon size='40'>mdi-close</v-icon>
      </v-btn>
      <div class='justify-center d-flex px-10 py-5 color-text' style='margin-top: 50px'>
        <v-progress-circular v-show='!show' style='margin-top: 100px; margin-bottom: 100px;' color='primary' indeterminate :size='50' :width='8'></v-progress-circular>
        <img class='img-view' v-show="show" @load="show=true" :src='incident?.image' alt='incidente'>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang='scss'>
.button-close {
  position: absolute; top: 50px; right: 20px;
}

img {
  cursor: pointer;
  width: 80%;
  height: auto;
}

.img-view {
  width: 80%;
  height: auto;
}

.color-text {
  color: $secondaryLight !important;
}

// RESPONSIVE 390PX

@media only screen and (max-width: 390px) {
  .button-close {
    position: absolute; top: 20px; right: 10px;
  }
  .img-visualizer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-view {
    width: 100%;
    height: auto;
  }
}
</style>
