import { API_URL } from '@/core/constants/urls.constants';
import AxiosClient from '@/core/api/api.client';
import { Incident, IncidentList } from '@/core/types/Incidents';

const IncidentsService = {
  getIncidentById: async (id: number) => AxiosClient.get<Incident>(`${API_URL}/incidents/${id}`),
  getIncidentByTripId: async (tripId: number) => AxiosClient.get<Incident[]>(`${API_URL}/incidents/trip/${tripId}`),
  getAllIncidents: async () => AxiosClient.get<IncidentList[]>(`${API_URL}/incidents`),
  getIncidentByOrderId: async (orderId: number) => AxiosClient.get<Incident[]>(`${API_URL}/incidents/order/${orderId}`),
};

export default IncidentsService;
