import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-star rounded-circle bg-dangerLight" }
const _hoisted_2 = {
  key: 0,
  class: "body--bold--medium color-text"
}
const _hoisted_3 = { class: "body--bold--small color-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CardPoll',
  props: {
    pollResponse: {},
    order: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    width: "100%",
    class: "poll-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, {
          color: "primary",
          size: "20"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("mdi-file-certificate")
          ])),
          _: 1
        })
      ]),
      (!_ctx.pollResponse.answeredPoll)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "No contestó la encuesta"))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "body--medium--small color-text-light" }, "Destinatario:", -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.pollResponse.destination), 1),
      (_ctx.pollResponse.answeredPoll)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            class: "text-capitalize mt-auto mb-0",
            color: "black",
            density: "comfortable",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pollResponse.seeResults(_ctx.order)))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Ver resultados")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})