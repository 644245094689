<script setup lang='ts'>
import { defineProps, ref } from 'vue';
import { ConfigMap, Marker } from '@/core/types/GoogleMap';

defineEmits(['findLocation', 'findLocationRefreshing']);

defineProps<{
  marker?: Marker
  config?: ConfigMap,
  class?: string,
  isErrorLocation: boolean,
  isLoadingLocation: boolean,
}>();

const options = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: false,
};
const zoom = 16;

const configMapDefault = ref({
  center: {
    lat: 19.4886016,
    lng: -99.1801444,
  },
});
</script>

<template>
  <div class='google-map-content'>
    <div v-if='isErrorLocation' class='google-map-content__error'>
      <img src='@/assets/iconsApp/errorLoading.svg' alt='error loading map'>
      <div>
        <p class='google-map-content__error__text'>Lo sentimos, hubo un error al <br> cargar la ubicación de la unidad</p>
        <v-btn variant='text' color='primary' @click.prevent="$emit('findLocation',marker?.id)">Volver a intentar</v-btn>
      </div>
    </div>
    <div v-if='!isErrorLocation'>
    <GMapMap
      :class="['google-map-content__map']"
      :center=' { ...marker?.position || configMapDefault.center }'
      :zoom='zoom'
      :options='options'
      map-type-id='terrain'
    >
        <GMapMarker
          v-if='!marker?.status && !isLoadingLocation'
          class='google-map-content__map__marker'
          :icon="{
            scaledSize: {width: 80, height: 80},
            url: require('@/assets/iconsApp/Localizador.png')
          }"
          :position='marker?.position'
        />
    </GMapMap>
    <div v-if='isLoadingLocation || marker?.status' class='google-map-content__overlay'>
      <div v-if='marker?.status' class='google-map-content__overlay__finish'>
        <p class='google-map-content__overlay__finish__text'>Viaje ya finalizado</p>
      </div>
      <v-progress-circular v-if='isLoadingLocation' class='google-map-content__overlay__spinner' indeterminate></v-progress-circular>
    </div>
    <v-btn v-if='!(isLoadingLocation || marker?.status)' class='google-map-content__btn' color='primary' size='large'
           @click.prevent="$emit('findLocationRefreshing')">ACTUALIZAR MAPA
    </v-btn>
    </div>
  </div>
</template>

<style lang='scss'>
@import 'src/scss/_variable.scss';

.google-map-content {
  height: 500px;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #BBB;
  &__error {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
      font-size: 1rem;
      font-family: Gotham-Medium,sans-serif;
      font-weight: 500;
      color: $primaryHover;
      margin: 1rem 0;
    }
  }

  &__map {
    height: 500px;
    width: 100%;

    &__marker {
      height: 3rem;
      width: 3rem;
    }
  }

  &__btn {
    border-radius: 3.5rem;
    top: 1rem;
    right: 1rem;
    position: absolute;
  }
  &__overlay{
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(255,255,255,0.8);
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
    &__finish{
      background: $dangerLight;
      color: $primaryHover;
      padding: 1rem;
      border-radius: 0.5rem;
      border: solid 1px $primaryHover;
      &__text{
        font-family: Gotham-Medium,sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
    &__spinner {
      color: $primary;
    }
  }
}

.gmnoprint {
  div {
    background: none !important;
    box-shadow: none !important;
    height: auto !important;
    cursor: default !important;

    button {
      margin-bottom: 0.5rem !important;
      background: #FFF !important;
      border-radius: 0.5rem;
    }

    button:nth-child(even) {
      margin-bottom: 1rem !important;
    }
  }
}

.gm-bundled-control {
  right: 2.75rem !important;
  bottom: 5.75rem !important;
}

.gm-style-cc {
  display: none;
}
</style>
