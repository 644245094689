import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-start align-center mt-4" }
const _hoisted_2 = { class: "container-icon" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "d-flex justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-4 d-flex flex-wrap"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "body--medium--small text-disabled mt-4 text-left color-text-disabled"
}


import Evidence from '@/core/types/Evidences';


export default /*@__PURE__*/_defineComponent({
  __name: 'EvidenceDocumentation',
  props: {
    haveEvidences: { type: Boolean },
    evidences: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_icon, { size: "18" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("mdi-archive-arrow-down-outline")
          ])),
          _: 1
        })
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Evidencias", -1))
    ]),
    (props.haveEvidences)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!props.evidences)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_progress_circular, {
                  style: {"margin-top":"200px"},
                  color: "primary",
                  indeterminate: "",
                  size: 50,
                  width: 8
                })
              ]))
            : _createCommentVNode("", true),
          (props.evidences && props.evidences.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.evidences, (item, index) => {
                  return (_openBlock(), _createElementBlock("a", {
                    class: "card-evidences",
                    href: item.url,
                    target: "_blank",
                    key: index
                  }, [
                    _createElementVNode("img", {
                      class: "card-evidences__img",
                      src: require('@/assets/iconsApp/pdf.svg'),
                      alt: "icon PDF"
                    }, null, 8, _hoisted_7),
                    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "card-evidences__text" }, "Ver PDF completo", -1))
                  ], 8, _hoisted_6))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!props.haveEvidences || !props.evidences || props.evidences.length == 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, " No hay evidencias subidas "))
      : _createCommentVNode("", true)
  ], 64))
}
}

})