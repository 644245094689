import { API_URL } from '@/core/constants/urls.constants';
import {
  LoginData,
  LoginResponse,
  LoginClientDestinationResponse,
  RegisterData,
  RecoverData,
  RecoverResponse,
  RegisterResponse,
  ResponseMaterial,
  ResponsePlant,
  ResponseFleet,
  UserUpdateData,
  ResponseUserUpdateData,
} from '@/core/types/Auth';
import axiosClient from '../../../core/api/api.client';

const authService = {
  login: async (userData: LoginData) => axiosClient.post<LoginResponse>(`${API_URL}/auth/login`, userData),

  loginClientDestination: async (destinationCode: string) => axiosClient.get<LoginClientDestinationResponse>(`${API_URL}/v2/destinations/login/${destinationCode}`),

  logout: async (id_zam: string) => axiosClient.delete(`/v2/notifications/userzam/${id_zam}/deletewebpush`),

  getZamUserCoordinator: async (id_zam: string) => axiosClient.get(`/zam/users/${id_zam}`),

  getZamUserClient: async (id_zam: string) => axiosClient.get(`/clients/zam/${id_zam}`),

  register: async (userData: RegisterData) => axiosClient.post<RegisterResponse>(`${API_URL}/auth/signup`, userData),

  getFleets: async () => axiosClient.get<ResponseFleet[]>(`${API_URL}/fleets`),

  getMaterials: async () => axiosClient.get<ResponseMaterial[]>(`${API_URL}/materials`),

  getPlants: async () => axiosClient.get<ResponsePlant[]>(`${API_URL}/plants`),

  recovery: async (userData: RecoverData) => axiosClient.post<RecoverResponse>(`${API_URL}/auth/resend`, userData),

  getUser: async () => axiosClient.get<ResponseUserUpdateData>(`${API_URL}/persons`),

  userUpdate: async (userData: UserUpdateData) => {
    const sendUserData = {
      address: userData.address,
      email: userData.email,
      phone: userData.phone,
      password: userData.password,
    };
    if (sendUserData.address === '') delete sendUserData.address;
    if (sendUserData.email === '') delete sendUserData.email;
    if (sendUserData.phone === '') delete sendUserData.phone;
    if (sendUserData.password === '') delete sendUserData.password;
    return axiosClient.put<ResponseUserUpdateData>(`${API_URL}/persons`, sendUserData);
  },

};

export default authService;
