import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_2 = { class: "container-icon" }
const _hoisted_3 = { class: "ml-2 body--bold--medium color-text" }
const _hoisted_4 = { class: "body--medium--small color-text" }
const _hoisted_5 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_6 = { class: "container-icon" }
const _hoisted_7 = { class: "color-text" }
const _hoisted_8 = { class: "mt-2 d-flex justify-start align-start" }
const _hoisted_9 = { class: "d-flex flex-column font-description" }
const _hoisted_10 = { class: "body--medium--small" }
const _hoisted_11 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_12 = { class: "container-icon" }
const _hoisted_13 = { class: "color-text" }
const _hoisted_14 = { class: "mt-2 d-flex justify-start align-start" }
const _hoisted_15 = { class: "d-flex flex-column font-description" }
const _hoisted_16 = { class: "body--medium--small color-text" }
const _hoisted_17 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_18 = { class: "container-icon" }
const _hoisted_19 = { class: "body--medium--small color-text" }
const _hoisted_20 = { class: "body--medium--small color-text" }


import { TripDetail } from '@/core/types/TripDetail';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryTrip',
  props: {
    trip: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.trip?.cptes, (item, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      _createElementVNode("div", null, [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_v_icon, { size: "18" }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("mdi-assistant")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", _hoisted_3, "Cliente " + _toDisplayString(index + 1), 1)
                  ]),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.clientZam.name || '-'), 1)
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_v_icon, { size: "18" }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("mdi-map-marker-account")
                        ])),
                        _: 1
                      })
                    ]),
                    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "N° de PF", -1))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("p", _hoisted_10, " Dirección: " + _toDisplayString(item.id_cp || '-'), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _createVNode(_component_v_divider, { class: "my-5" }),
      _createElementVNode("div", null, [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_v_icon, { size: "18" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("mdi-map-marker")
                        ])),
                        _: 1
                      })
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Origen", -1))
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", _hoisted_16, " Dirección: " + _toDisplayString(item.orders[0].origin.address || '-'), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_v_icon, { size: "18" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("mdi-flag")
                        ])),
                        _: 1
                      })
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Destinos", -1))
                  ]),
                  _createElementVNode("p", _hoisted_19, " Destinatario: " + _toDisplayString(item.orders[0].destination.name || '-'), 1),
                  _createElementVNode("p", _hoisted_20, " Dirección: " + _toDisplayString(item.orders[0].destination.address || '-'), 1)
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024),
        (index < _ctx.trip?.cptes.length - 1)
          ? (_openBlock(), _createBlock(_component_v_divider, {
              key: 0,
              class: "my-5"
            }))
          : _createCommentVNode("", true)
      ])
    ], 64))
  }), 128))
}
}

})