/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_URL } from '@/core/constants/urls.constants';
import AxiosClient from '@/core/api/api.client';
import { NotificationsPagination } from '@/core/types/Notifications';

const NotificationsService = {
  getNotifications: async (userId: number, params: any) => {
    const paramsToSend = {
      page: params.page,
      size: params.size,
      onlyUnread: params.onlyUnread,
    };
    return AxiosClient.get<NotificationsPagination>(`${API_URL}/v2/notifications/user/${userId}`, { params: paramsToSend });
  },
  getSignedUrl: async (key: string) => {
    const paramsToSend = {
      key,
    };
    return AxiosClient.get<string>(`${API_URL}/v2/event/url`, { params: paramsToSend });
  },
  markAsSeen: async (notificationId: number) => AxiosClient.put(`${API_URL}/v2/notifications/mark/${notificationId}`),
  markAllAsSeen: async (userId: number) => AxiosClient.put(`${API_URL}/v2/notifications/user/${userId}`),
};

export default NotificationsService;
