import { RouteRecordRaw } from 'vue-router';

const MonitoringChiefChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeMonitoringChief',
    component: () => import('@/modules/Coordinator/views/HomeView.vue'),
  },
  {
    path: '/scorecard',
    name: 'scorecardMonitoringChief',
    component: () => import('@/modules/MonitoringChief/views/ScorecardView.vue'),
  },
];

export default MonitoringChiefChildrenRoutes;
