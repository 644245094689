import {
  createRouter,
  createWebHistory, NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { isUserAuthenticated } from '@/core/guards/AuthGuard';
import AuthChildrenRoutes from '@/modules/Auth/router';
import CoordinatorChildrenRoutes from '@/modules/Coordinator/router';
import ClientChildrenRoutes from '@/modules/Client/router';
import ManagerChildrenRoutes from '@/modules/Manager/router';
import OperationsChiefChildrenRoutes from '@/modules/OperationsChief/router';
import MonitoringChiefChildrenRoutes from '@/modules/MonitoringChief/router';
import ClientDestinationChildrenRoutes from '@/modules/ClientDestination/router';
import HumanCapitalChildrenRoutes from '@/modules/HumanCapital/router';
import OutboundChildrenRoutes from '@/modules/Outbound/router';
import OutboundCoordinatorChildrenRoutes from '@/modules/OutboundCoordinator/router';
import OrderTrackingChildrenRoutes from '../../modules/OrderTracking/router';

const getRoleId = () => {
  const role = localStorage.getItem('role');
  return role ? JSON.parse(role) : null;
};

const roleRoutes = {
  1: 'client',
  2: 'coordinator',
  4: 'monitoringChief',
  5: 'manager',
  6: 'operationsChief',
  7: 'humanCapital',
  8: 'outbound',
  9: 'outboundCoordinator',
  10: 'clientDestination',
};

const rolePermissions = {
  client: ['client'],
  coordinator: ['coordinator'],
  monitoringChief: ['monitoringChief'],
  manager: ['manager'],
  operationsChief: ['operationsChief'],
  humanCapital: ['humanCapital'],
  outbound: ['outbound'],
  outboundCoordinator: ['outboundCoordinator'],
  clientDestination: ['clientDestination'],
};

const checkIsLogin = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const roleId = getRoleId();
  if (!roleId) {
    next({ name: 'role-selection' });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    // component: () => import('@/shared/views/HomeView.vue'),
    redirect: () => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        const requiredPermissions = rolePermissions[role as keyof typeof rolePermissions];
        if (requiredPermissions) {
          return { name: role };
        }
        return { name: 'role-selection' };
      }
      return { name: 'role-selection' };
    },
    meta: {
      layout: 'ApplicationLayout',
      permissions: ['coordinator', 'client', 'manager', 'operationsChief', 'monitoringChief', 'humanCapital', 'outbound', 'outboundCoordinator', 'clientDestination'],
    },
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        const requiredPermissions = rolePermissions[role as keyof typeof rolePermissions];

        if (requiredPermissions) {
          next({ name: role });
        } else {
          next({ name: 'role-selection' });
        }
      } else {
        next({ name: 'role-selection' });
      }
    },
  },
  {
    path: '/auth',
    name: 'auth',
    children: AuthChildrenRoutes,
    meta: {
      layout: 'AuthLayout',
    },
    // beforeEnter: (to, from, next) => {
    //   const roleId = getRoleId();
    //   if (roleId) {
    //     next({ name: 'home' });
    //   } else {
    //     next();
    //   }
    // },
    redirect: () => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        return { name: role };
      }
      return { name: 'role-selection' };
    },
  },
  {
    path: '/coordinator',
    name: 'coordinator',
    children: CoordinatorChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['coordinator', 'manager', 'operationsChief', 'monitoringChief'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeCoordinator' },
  },
  {
    path: '/client',
    name: 'client',
    children: ClientChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['client', 'coordinator', 'operationsChief'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeClient' },
  },
  {
    path: '/manager',
    name: 'manager',
    children: ManagerChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['manager'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeManager' },
  },
  {
    path: '/operationsChief',
    name: 'operationsChief',
    children: OperationsChiefChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['operationsChief', 'client', 'coordinator'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeOperationsChief' },
  },
  {
    path: '/monitoringChief',
    name: 'monitoringChief',
    children: MonitoringChiefChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['monitoringChief'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeMonitoringChief' },
  },
  {
    path: '/humanCapital',
    name: 'humanCapital',
    children: HumanCapitalChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['humanCapital', 'client', 'coordinator', 'manager', 'operationsChief', 'monitoringChief'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'homeHumanCapital' },
  },
  {
    path: '/outbound',
    name: 'outbound',
    children: OutboundChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['outbound', 'outboundCoordinator', 'client', 'coordinator', 'manager', 'operationsChief', 'monitoringChief', 'humanCapital'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'HomeOutbound' },

  },
  {
    path: '/destinatario',
    name: 'orderTracking',
    children: OrderTrackingChildrenRoutes,
    meta: {
      layout: 'AuthLayout',
      requiresAuth: true,
      permissions: ['orderTracking'],
    },
    redirect: () => {
      const routeName = isUserAuthenticated() ? 'homeOrderTracking' : 'loginOrderTracking';
      return { name: routeName };
    },
  },
  {
    path: '/destination',
    name: 'clientDestination',
    children: ClientDestinationChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['clientDestination'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'DetailTrip' },
  },
  {
    path: '/outboundCoordinator',
    name: 'outboundCoordinator',
    children: OutboundCoordinatorChildrenRoutes,
    meta: {
      layout: 'ApplicationLayout',
      requiresAuth: true,
      permissions: ['outbound', 'outboundCoordinator', 'client', 'coordinator', 'manager', 'operationsChief', 'monitoringChief', 'humanCapital'],
    },
    beforeEnter: checkIsLogin,
    redirect: { name: 'HomeOutboundCoordinator' },
  },
  /* {
    path: '/tracking',
    name: 'Tracking',
    children: AuthChildrenRoutes,
    meta: {
      layout: 'AuthLayout',
    },
    redirect: { name: 'LoginDestination' },
  }, */
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: () => {
      console.log('not found');
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        const requiredPermissions = rolePermissions[role as keyof typeof rolePermissions];

        if (requiredPermissions) {
          return { name: role };
        }
        return { name: 'role-selection' };
      }
      return { name: 'role-selection' };
    },
    beforeEnter: (to, from, next) => {
      const isAuth = isUserAuthenticated();
      if (!isAuth) {
        next({ name: 'role-selection' });
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.permissions) {
    const permissions = to.meta.permissions as string[];
    const roleId = getRoleId();
    const role = roleRoutes[roleId as keyof typeof roleRoutes];
    const requiredPermissions = rolePermissions[role as keyof typeof rolePermissions];
    const hasPermission = permissions.some((permission) => requiredPermissions.includes(permission));
    if (hasPermission) {
      next();
    } else {
      console.log('No permission');
      next({ name: 'not-found' });
    }
  } else {
    console.log('No permission 2');
    next();
  }
});

export default router;
