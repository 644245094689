<script setup lang='ts'>
import {
  ref,
  watch,
} from 'vue';
import { AxiosResponse } from 'axios';
import { TripDetail } from '@/core/types/TripDetail';
import { TypeTrip } from '@/core/types/TripsWithPagination';
import GoogleMap from '@/shared/components/GoogleMap.vue';
import { Marker } from '@/core/types/GoogleMap';
import IncidentsService from '@/shared/services/IncidentsService';
import { Incident } from '@/core/types/Incidents';
import EvidencesService from '@/shared/services/EvidencesService';
import Evidence from '@/core/types/Evidences';
import DirectTrip from '@/shared/components/DetailTrip/components/TypeTrips/DirectTrip.vue';
import DeliveryTrip from '@/shared/components/DetailTrip/components/TypeTrips/DeliveryTrip.vue';
import ConsolidatedTrip
  from '@/shared/components/DetailTrip/components/TypeTrips/ConsolidatedTrip.vue';
import EvidenceDocumentation
  from '@/shared/components/DetailTrip/components/EvidenceDocumentation.vue';
import IncidentDocumentation
  from '@/shared/components/DetailTrip/components/IncidentDocumentation.vue';
import TripStatus from '@/shared/components/DetailTrip/components/TripStatus.vue';
import CardPoll from '@/shared/components/DetailTrip/components/CardPoll.vue';

defineEmits(['findLocation', 'findLocationRefreshing', 'order']);

const props = defineProps<{
  trip?: TripDetail,
  loadingDetail: boolean,
  typeTrip: TypeTrip,
  marker?: Marker,
  isErrorLocation: boolean,
  isLoadingLocation: boolean,
  haveIncidents: boolean,
  haveEvidences: boolean,
}>();

const haveIncidents = ref(false);
const haveEvidences = ref(false);

const incidents = ref<Incident[]>([]);
const evidences = ref<Evidence[]>([]);

const getIncidents = () => {
  incidents.value = [];
  haveIncidents.value = false;
  IncidentsService.getIncidentByTripId(props.trip?.id || 0)
    .then((response) => {
      haveIncidents.value = true;
      incidents.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getEvidences = () => {
  evidences.value = [];
  haveEvidences.value = false;
  if (props.haveEvidences) {
    haveEvidences.value = true;
    EvidencesService.getEvidences(props.trip?.id || 0)
      .then((response: AxiosResponse<Evidence[]>) => {
        evidences.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

watch(() => props.trip, () => {
  getEvidences();
  getIncidents();
});
</script>

<template>
  <template v-if='loadingDetail'>
    <v-progress-circular style='margin-top: 200px' color='primary' indeterminate :size='50'
      :width='8'></v-progress-circular>
  </template>
  <template v-if='!loadingDetail'>
    <div class='d-flex justify-end mt-5 mb-2'>
      <v-chip color='success' class='body--bold--medium justify-center align-center d-flex custom-chip mr-5'
        prepend-icon='mdi mdi-truck' :text='typeTrip'>
      </v-chip>
    </div>
    <div class='detail-content'>
      <div class='detail-content__colum-width'>
        <div class='map-mobile'>
          <GoogleMap :config='{ center: marker?.position }' :marker='marker'
            @find-location="(idTrip) => $emit('findLocation', idTrip)"
            @find-location-refreshing="() => $emit('findLocationRefreshing')" :isErrorLocation='isErrorLocation'
            :isLoadingLocation='isLoadingLocation' />
          <TripStatus class='status-mobile' v-if='trip' :trip='trip' />
          <h3 class='text-left mt-6'>Detalle de viaje</h3>
        </div>
        <v-card class='mx-1'>
          <div class='card-body text-left py-4 px-4'>
            <DirectTrip :trip='trip' v-if='typeTrip === "Directo"' />
            <ConsolidatedTrip :trip='trip' v-if='typeTrip === "Consolidado"' />
            <DeliveryTrip :trip='trip' v-if='typeTrip === "Reparto"' />
          </div>
        </v-card>
        <TripStatus class='status-desktop' v-if='trip' :trip='trip' />
      </div>
      <div class='detail-content__colum-width'>
        <div class='map-desktop'>
          <GoogleMap :config='{ center: marker?.position }' :marker='marker'
            @find-location="(idTrip) => $emit('findLocation', idTrip)"
            @find-location-refreshing="() => $emit('findLocationRefreshing')" :isErrorLocation='isErrorLocation'
            :isLoadingLocation='isLoadingLocation' />
        </div>
        <IncidentDocumentation :haveIncidents='haveIncidents' :incidents='incidents' />
        <EvidenceDocumentation :haveEvidences='haveEvidences' :evidences='evidences' />
        <div>
          <div class='d-flex justify-start align-center mt-2 mt-sm-4'>
            <div class='container-star rounded-circle bg-dangerLight'>
              <v-icon size='20' color="primary">mdi-star</v-icon>
            </div>
            <p class='ml-2 body--bold--medium color-text'>Encuesta de opinión</p>
          </div>
          <v-row no-gutters class="container-gap flex-column flex-sm-row">
            <div v-for="(cpte, i) in trip?.cptes" :key="i"
              class="d-sm-flex flex-wrap container-gap pb-6 pb-sm-2 pb-md-0 pb-md-auto mt-sm-4">
              <v-col v-for="(order, j) in cpte.orders" :key="j" class="px-0 px-sm-auto" xs="11" :sm="cpte.orders.length > 1 ? 'auto' : 12">
                <CardPoll class="card-width"
                  :pollResponse="{ destination: order?.destination?.name || '-', seeResults: () => $emit('order', order), answeredPoll: order.pollOrders.length > 0 }"
                  :order="order" />
              </v-col>
            </div>
          </v-row>
        </div>
      </div>
    </div>
  </template>
</template>

<style scoped lang='scss'>
@import '../../../scss/variable';

.poll-card {
  height: 158px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: left;
  padding: 1rem 0.625rem;
}

.container-gap {
  grid-gap: 1.5rem;
}

.container-star {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-content {
  display: flex;

  &__colum-width {
    width: 50%;
    padding: 1rem;
  }
}

.custom-chip {
  border-radius: 0.5rem !important;

  &__v-chip__content {
    padding: 0 !important;
  }

  &__v-chip__prepend {
    margin-top: -8px !important;
  }
}

.map-desktop {
  display: block;
}

.map-mobile {
  display: none;
}

.status-desktop {
  display: block;
}

.status-mobile {
  display: none;
}

@media (max-width: 768px) {
  .detail-content {
    display: block;

    &__colum-width {
      width: 100%;
    }
  }

  .map-desktop {
    display: none;
  }

  .map-mobile {
    display: block;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 390px) {

  .status-desktop {
    display: none;
  }

  .status-mobile {
    display: block;
  }

  .detail-content {
    &__colum-width {
      padding: 0.1rem;
    }
  }
}

@media (min-width: 768px) {
  .card-width {
    width: 250px !important;
  }
}
</style>
