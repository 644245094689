import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-end mt-5 mb-2" }
const _hoisted_2 = { class: "detail-content" }
const _hoisted_3 = { class: "detail-content__colum-width" }
const _hoisted_4 = { class: "map-mobile" }
const _hoisted_5 = { class: "card-body text-left py-4 px-4" }
const _hoisted_6 = { class: "detail-content__colum-width" }
const _hoisted_7 = { class: "map-desktop" }
const _hoisted_8 = { class: "d-flex justify-start align-center mt-2 mt-sm-4" }
const _hoisted_9 = { class: "container-star rounded-circle bg-dangerLight" }

import {
  ref,
  watch,
} from 'vue';
import { AxiosResponse } from 'axios';
import { TripDetail } from '@/core/types/TripDetail';
import { TypeTrip } from '@/core/types/TripsWithPagination';
import GoogleMap from '@/shared/components/GoogleMap.vue';
import { Marker } from '@/core/types/GoogleMap';
import IncidentsService from '@/shared/services/IncidentsService';
import { Incident } from '@/core/types/Incidents';
import EvidencesService from '@/shared/services/EvidencesService';
import Evidence from '@/core/types/Evidences';
import DirectTrip from '@/shared/components/DetailTrip/components/TypeTrips/DirectTrip.vue';
import DeliveryTrip from '@/shared/components/DetailTrip/components/TypeTrips/DeliveryTrip.vue';
import ConsolidatedTrip
  from '@/shared/components/DetailTrip/components/TypeTrips/ConsolidatedTrip.vue';
import EvidenceDocumentation
  from '@/shared/components/DetailTrip/components/EvidenceDocumentation.vue';
import IncidentDocumentation
  from '@/shared/components/DetailTrip/components/IncidentDocumentation.vue';
import TripStatus from '@/shared/components/DetailTrip/components/TripStatus.vue';
import CardPoll from '@/shared/components/DetailTrip/components/CardPoll.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailTrip',
  props: {
    trip: {},
    loadingDetail: { type: Boolean },
    typeTrip: {},
    marker: {},
    isErrorLocation: { type: Boolean },
    isLoadingLocation: { type: Boolean },
    haveIncidents: { type: Boolean },
    haveEvidences: { type: Boolean }
  },
  emits: ['findLocation', 'findLocationRefreshing', 'order'],
  setup(__props: any) {



const props = __props;

const haveIncidents = ref(false);
const haveEvidences = ref(false);

const incidents = ref<Incident[]>([]);
const evidences = ref<Evidence[]>([]);

const getIncidents = () => {
  incidents.value = [];
  haveIncidents.value = false;
  IncidentsService.getIncidentByTripId(props.trip?.id || 0)
    .then((response) => {
      haveIncidents.value = true;
      incidents.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getEvidences = () => {
  evidences.value = [];
  haveEvidences.value = false;
  if (props.haveEvidences) {
    haveEvidences.value = true;
    EvidencesService.getEvidences(props.trip?.id || 0)
      .then((response: AxiosResponse<Evidence[]>) => {
        evidences.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

watch(() => props.trip, () => {
  getEvidences();
  getIncidents();
});

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loadingDetail)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          style: {"margin-top":"200px"},
          color: "primary",
          indeterminate: "",
          size: 50,
          width: 8
        }))
      : _createCommentVNode("", true),
    (!_ctx.loadingDetail)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_chip, {
              color: "success",
              class: "body--bold--medium justify-center align-center d-flex custom-chip mr-5",
              "prepend-icon": "mdi mdi-truck",
              text: _ctx.typeTrip
            }, null, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(GoogleMap, {
                  config: { center: _ctx.marker?.position },
                  marker: _ctx.marker,
                  onFindLocation: _cache[0] || (_cache[0] = (idTrip) => _ctx.$emit('findLocation', idTrip)),
                  onFindLocationRefreshing: _cache[1] || (_cache[1] = () => _ctx.$emit('findLocationRefreshing')),
                  isErrorLocation: _ctx.isErrorLocation,
                  isLoadingLocation: _ctx.isLoadingLocation
                }, null, 8, ["config", "marker", "isErrorLocation", "isLoadingLocation"]),
                (_ctx.trip)
                  ? (_openBlock(), _createBlock(TripStatus, {
                      key: 0,
                      class: "status-mobile",
                      trip: _ctx.trip
                    }, null, 8, ["trip"]))
                  : _createCommentVNode("", true),
                _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-left mt-6" }, "Detalle de viaje", -1))
              ]),
              _createVNode(_component_v_card, { class: "mx-1" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.typeTrip === "Directo")
                      ? (_openBlock(), _createBlock(DirectTrip, {
                          key: 0,
                          trip: _ctx.trip
                        }, null, 8, ["trip"]))
                      : _createCommentVNode("", true),
                    (_ctx.typeTrip === "Consolidado")
                      ? (_openBlock(), _createBlock(ConsolidatedTrip, {
                          key: 1,
                          trip: _ctx.trip
                        }, null, 8, ["trip"]))
                      : _createCommentVNode("", true),
                    (_ctx.typeTrip === "Reparto")
                      ? (_openBlock(), _createBlock(DeliveryTrip, {
                          key: 2,
                          trip: _ctx.trip
                        }, null, 8, ["trip"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }),
              (_ctx.trip)
                ? (_openBlock(), _createBlock(TripStatus, {
                    key: 0,
                    class: "status-desktop",
                    trip: _ctx.trip
                  }, null, 8, ["trip"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(GoogleMap, {
                  config: { center: _ctx.marker?.position },
                  marker: _ctx.marker,
                  onFindLocation: _cache[2] || (_cache[2] = (idTrip) => _ctx.$emit('findLocation', idTrip)),
                  onFindLocationRefreshing: _cache[3] || (_cache[3] = () => _ctx.$emit('findLocationRefreshing')),
                  isErrorLocation: _ctx.isErrorLocation,
                  isLoadingLocation: _ctx.isLoadingLocation
                }, null, 8, ["config", "marker", "isErrorLocation", "isLoadingLocation"])
              ]),
              _createVNode(IncidentDocumentation, {
                haveIncidents: haveIncidents.value,
                incidents: incidents.value
              }, null, 8, ["haveIncidents", "incidents"]),
              _createVNode(EvidenceDocumentation, {
                haveEvidences: haveEvidences.value,
                evidences: evidences.value
              }, null, 8, ["haveEvidences", "evidences"]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_v_icon, {
                      size: "20",
                      color: "primary"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("mdi-star")
                      ])),
                      _: 1
                    })
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Encuesta de opinión", -1))
                ]),
                _createVNode(_component_v_row, {
                  "no-gutters": "",
                  class: "container-gap flex-column flex-sm-row"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trip?.cptes, (cpte, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "d-sm-flex flex-wrap container-gap pb-6 pb-sm-2 pb-md-0 pb-md-auto mt-sm-4"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cpte.orders, (order, j) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            key: j,
                            class: "px-0 px-sm-auto",
                            xs: "11",
                            sm: cpte.orders.length > 1 ? 'auto' : 12
                          }, {
                            default: _withCtx(() => [
                              _createVNode(CardPoll, {
                                class: "card-width",
                                pollResponse: { destination: order?.destination?.name || '-', seeResults: () => _ctx.$emit('order', order), answeredPoll: order.pollOrders.length > 0 },
                                order: order
                              }, null, 8, ["pollResponse", "order"])
                            ]),
                            _: 2
                          }, 1032, ["sm"]))
                        }), 128))
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})