import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "px-5" }
const _hoisted_2 = { class: "body--medium--xlarge color-text" }
const _hoisted_3 = {
  class: "body--medium--small",
  style: {"color":"#857c7c","font-weight":"100 !important"}
}
const _hoisted_4 = { class: "px-5 mt-3 mb-3" }
const _hoisted_5 = {
  class: "body--medium--medium",
  style: {"font-family":"inherit !important"}
}
const _hoisted_6 = {
  key: 0,
  class: "px-5 my-5"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "px-5"
}
const _hoisted_9 = { class: "img-visualizer overflow-auto" }
const _hoisted_10 = {
  class: "justify-center d-flex px-10 py-5 color-text",
  style: {"margin-top":"50px"}
}
const _hoisted_11 = ["src"]

import { ref } from 'vue';
import NotificationsService from '@/shared/services/NotificationsService';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewDetailHumanCapital',
  props: {
    title: {},
    date: {},
    description: {},
    link: {},
    file: {},
    onClick: { type: Function }
  },
  setup(__props: any) {



const show = ref(false);
const isDownloadSuccess = ref(false);

const downloadFile = (file: string) => {
  try {
    NotificationsService.getSignedUrl(file)
      .then((response: AxiosResponse<string>) => {
        const url = response.data;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = url;
        link.click();
        isDownloadSuccess.value = true;
      })
      .catch((error) => {
        console.log(error);
        isDownloadSuccess.value = false;
      })
      .finally(() => {
        isDownloadSuccess.value = true;
      });
  } catch (error) {
    console.log(error);
    isDownloadSuccess.value = false;
  }
};


return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "mt-10" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "body--bold--xlarge color-text" }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Notificación")
          ])),
          _: 1
        }),
        _createVNode(_component_v_chip, {
          class: "filter-chips__chip mt-2 mb-5 ml-3",
          variant: "outlined"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" CAPITAL HUMANO ")
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.date), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1)
        ]),
        (_ctx.link && _ctx.link !== "")
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "body--medium--medium color-text mb-2" }, "Link compartido", -1)),
              _createElementVNode("a", {
                class: "body--light--large color-text",
                href: _ctx.link,
                target: "_blank",
                style: {"color":"rgb(var(--v-theme-primary)) !important","text-decoration":"none"}
              }, _toDisplayString(_ctx.link || '-'), 9, _hoisted_7)
            ]))
          : _createCommentVNode("", true),
        (_ctx.file && _ctx.file !== "")
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "body--medium--medium color-text mb-2" }, "Documento Adjunto", -1)),
              _createVNode(_component_v_btn, {
                class: "body--medium--medium",
                color: "primary",
                variant: "elevated",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadFile(_ctx.file)))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Descargar "),
                  _createElementVNode("i", {
                    class: "mdi mdi-download",
                    style: {"margin-left":"0.5rem","font-size":"1.2rem"}
                  }, null, -1)
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_card_actions, { class: "d-flex justify-end my-2 pr-5" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "body--medium--medium",
              color: "primary",
              variant: "elevated",
              onClick: _ctx.onClick
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Cerrar")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.expandImg,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expandImg) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_btn, {
            class: "button-close",
            color: "white",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandImg=false)),
            variant: "text",
            icon: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "40" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createVNode(_component_v_progress_circular, {
              style: {"margin-top":"100px","margin-bottom":"100px"},
              color: "primary",
              indeterminate: "",
              size: 50,
              width: 8
            }, null, 512), [
              [_vShow, !show.value]
            ]),
            _withDirectives(_createElementVNode("img", {
              class: "img-view",
              onLoad: _cache[2] || (_cache[2] = ($event: any) => (show.value=true)),
              src: _ctx.incident?.image,
              alt: "incidente"
            }, null, 40, _hoisted_11), [
              [_vShow, show.value]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_snackbar, {
      timeout: 2000,
      color: isDownloadSuccess.value ? 'success': 'danger',
      elevation: "24",
      "model-value": isDownloadSuccess.value
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(['mdi',{ 'mdi-check-circle-outline' : isDownloadSuccess.value, 'mdi-information-outline' : !isDownloadSuccess.value }])
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(isDownloadSuccess.value ? 'El documento se descargó exitosamente' : 'Ocurrio un error al descargar el documento, intentelo nuevamente.'), 1)
      ]),
      _: 1
    }, 8, ["color", "model-value"])
  ], 64))
}
}

})