<script setup lang='ts'>
import { onMounted, ref } from 'vue';
import DesignationLayout from '@/core/layouts/DesignationLayout.vue';
import SplashView from '@/shared/views/SplashView.vue';

const loadingSplash = ref(true);

onMounted(() => {
  setTimeout(() => {
    loadingSplash.value = false;
  }, 3000);
});

</script>

<template>
    <SplashView v-if='loadingSplash'/>
    <DesignationLayout v-else>
      <RouterView v-slot='{ Component }'>
        <component :is='Component' />
      </RouterView>
    </DesignationLayout>

</template>

<style lang="scss">
@import '@/scss/_variable.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: $primary;
    }
  }
}
</style>
