<script setup>
</script>

<template>
  <div class="v-overlay container-background">
    <img src="../../assets/logos/td-logo-transparente.svg" alt="Transdelta logo" srcset="" />
    <v-progress-circular color="white" indeterminate :size="90" :width="12.3"></v-progress-circular>
  </div>
</template>

<style lang="scss">
.container-background {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  background: $bg-gradient;
  .v-progress-circular__underlay {
    visibility: hidden;
  }
}
</style>
