import { RouteRecordRaw } from 'vue-router';

const CoordinatorChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeCoordinator',
    component: () => import('@/modules/Coordinator/views/HomeView.vue'),
  },
  {
    path: '/trips',
    name: 'TripsList',
    component: () => import('@/shared/views/TripsListView.vue'),
  },
  {
    path: '/history-trips',
    name: 'HistoryTripsList',
    component: () => import('@/shared/views/HistoryTripsListView.vue'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/shared/views/MyAccountView.vue'),
  },
  {
    path: '/select-communication',
    name: 'SelectCommunication',
    component: () => import('@/shared/views/CommunicationSelectionView.vue'),
  },
  {
    path: '/communication/:role',
    name: 'Communication',
    component: () => import('@/shared/views/CommunicationListView.vue'),
  },
  {
    path: '/incidents',
    name: 'Incidents',
    component: () => import('@/shared/views/IncidentsView.vue'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/shared/views/NotificationsView.vue'),
  },
];

export default CoordinatorChildrenRoutes;
