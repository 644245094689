const isUserAuthenticated = () => {
  const isAuthenticated = localStorage.getItem('userData');
  if (isAuthenticated) {
    return JSON.parse(isAuthenticated);
  }
  return false;
};

const isUserAdmin = () => localStorage.getItem('userData') === 'admin';

const setAuthenticated = () => {
  const isAuth = isUserAuthenticated();
  localStorage.setItem('isAuthenticated', JSON.stringify(!isAuth));
};

export { isUserAdmin, isUserAuthenticated, setAuthenticated };
