import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/iconsApp/errorLoading.svg'


const _hoisted_1 = { class: "google-map-content" }
const _hoisted_2 = {
  key: 0,
  class: "google-map-content__error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "google-map-content__overlay"
}
const _hoisted_5 = {
  key: 0,
  class: "google-map-content__overlay__finish"
}

import { ref } from 'vue';
import { ConfigMap, Marker } from '@/core/types/GoogleMap';

const zoom = 16;


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleMap',
  props: {
    marker: {},
    config: {},
    class: {},
    isErrorLocation: { type: Boolean },
    isLoadingLocation: { type: Boolean }
  },
  emits: ['findLocation', 'findLocationRefreshing'],
  setup(__props: any) {





const options = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: false,
};
const configMapDefault = ref({
  center: {
    lat: 19.4886016,
    lng: -99.1801444,
  },
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isErrorLocation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_0,
            alt: "error loading map"
          }, null, -1)),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "google-map-content__error__text" }, [
              _createTextVNode("Lo sentimos, hubo un error al "),
              _createElementVNode("br"),
              _createTextVNode(" cargar la ubicación de la unidad")
            ], -1)),
            _createVNode(_component_v_btn, {
              variant: "text",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('findLocation',_ctx.marker?.id)), ["prevent"]))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Volver a intentar")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isErrorLocation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_GMapMap, {
            class: _normalizeClass(['google-map-content__map']),
            center:  { ..._ctx.marker?.position || configMapDefault.value.center },
            zoom: zoom,
            options: options,
            "map-type-id": "terrain"
          }, {
            default: _withCtx(() => [
              (!_ctx.marker?.status && !_ctx.isLoadingLocation)
                ? (_openBlock(), _createBlock(_component_GMapMarker, {
                    key: 0,
                    class: "google-map-content__map__marker",
                    icon: {
            scaledSize: {width: 80, height: 80},
            url: require('@/assets/iconsApp/Localizador.png')
          },
                    position: _ctx.marker?.position
                  }, null, 8, ["icon", "position"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["center"]),
          (_ctx.isLoadingLocation || _ctx.marker?.status)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.marker?.status)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[5] || (_cache[5] = [
                      _createElementVNode("p", { class: "google-map-content__overlay__finish__text" }, "Viaje ya finalizado", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.isLoadingLocation)
                  ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                      key: 1,
                      class: "google-map-content__overlay__spinner",
                      indeterminate: ""
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!(_ctx.isLoadingLocation || _ctx.marker?.status))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                class: "google-map-content__btn",
                color: "primary",
                size: "large",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('findLocationRefreshing')), ["prevent"]))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("ACTUALIZAR MAPA ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})