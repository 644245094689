import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-start align-center mt-4" }
const _hoisted_2 = { class: "container-icon" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "d-flex justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-4 d-flex justify-start align-start flex-wrap"
}
const _hoisted_6 = {
  key: 1,
  class: "body--medium--small text-disabled mt-4 text-left color-text-disabled"
}

import { ref } from 'vue';

import CardIncident from '@/shared/components/CardIncident.vue';
import ViewDetailIncident from '@/shared/components/ViewDetailIncident.vue';
import { Incident } from '@/core/types/Incidents';


export default /*@__PURE__*/_defineComponent({
  __name: 'IncidentDocumentation',
  props: {
    haveIncidents: { type: Boolean },
    incidents: {}
  },
  setup(__props: any) {


const props = __props;

const showIncidents = ref(false);
const incidentSelected = ref<Incident>();

const setIncidents = (index: number) => {
  if (props.incidents[index]) {
    incidentSelected.value = props.incidents[index];
    showIncidents.value = !showIncidents.value;
  }
};


return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_icon, { size: "18" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("mdi-bus-alert")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Incidentes", -1))
    ]),
    (props.haveIncidents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!props.incidents)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_progress_circular, {
                  style: {"margin-top":"200px"},
                  color: "primary",
                  indeterminate: "",
                  size: 50,
                  width: 8
                })
              ]))
            : _createCommentVNode("", true),
          (props.incidents)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.incidents, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cards-custom",
                    key: index
                  }, [
                    _createVNode(CardIncident, {
                      incident: item,
                      onClick: () => setIncidents(index)
                    }, null, 8, ["incident", "onClick"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!props.haveIncidents)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, " No hay incidentes "))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: showIncidents.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showIncidents).value = $event)),
      persistent: "",
      width: "600"
    }, {
      default: _withCtx(() => [
        _createVNode(ViewDetailIncident, {
          incident: incidentSelected.value,
          "on-click": () => showIncidents.value = false
        }, null, 8, ["incident", "on-click"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})