import { AxiosInstance } from 'axios';

const setTokenInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');

      if (token) {
        const updatedConfig = { ...config };
        updatedConfig.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  let isRefreshing = false;

  let refreshSubscribers: ((token: unknown) => void)[] = [];

  axiosInstance.interceptors.response.use(
    (response) => {
      const newToken = response.headers['new-token'];

      if (newToken) {
        localStorage.setItem('token', newToken);
      }

      return response;
    },
    (err) => {
      const error = { ...err };

      if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          const token = localStorage.getItem('token');
          return axiosInstance
            .get('/auth/refreshToken', { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
              const newToken = response.data.token;

              localStorage.setItem('token', newToken);

              refreshSubscribers.forEach((subscriber) => subscriber(newToken));
              refreshSubscribers = [];

              return axiosInstance(error.config);
            })
            .catch((refreshError) => {
              localStorage.removeItem('token');
              window.location.href = '/auth';
              return Promise.reject(refreshError);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        return new Promise((resolve) => {
          refreshSubscribers.push((newToken) => {
            error.config.headers.Authorization = `Bearer ${newToken}`;
            resolve(axiosInstance(error.config));
          });
        });
      }
      return Promise.reject(error);
    },
  );
};

export default setTokenInterceptor;
