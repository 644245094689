<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script lang='ts'>
import { markRaw, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import ApplicationLayout from './ApplicationLayout.vue';

export default {
  name: 'DesignationLayout',
  setup() {
    const route = useRoute();
    const layout = shallowRef();
    const getLayout = async (_layout : string) => (await import(`@/core/layouts/${_layout}.vue`)).default;
    watch(
      () => route.meta,
      async (meta) => {
        if (!meta.layout || meta.layout === ApplicationLayout) {
          layout.value = ApplicationLayout;
          return;
        }
        try {
          layout.value = markRaw(await getLayout(meta.layout as string));
        } catch (err) {
          console.log(err);
          layout.value = ApplicationLayout;
        }
      },
      { immediate: true },
    );
    return { layout };
  },
};
</script>
