import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-drawer__title mt-4" }




export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderDrawer',
  props: {
    text: {},
    onClick: { type: Function },
    alwaysDisplay: { type: Boolean }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-drawer", _ctx.alwaysDisplay ? "" : "header-drawer--hidden"])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.text || 'Detalle de viaje'), 1),
    _createVNode(_component_v_btn, {
      onClick: _ctx.onClick,
      variant: "text",
      icon: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("mdi-close")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 2))
}
}

})