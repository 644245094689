import { RouteRecordRaw } from 'vue-router';

const OrderTrackingChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: 'seguimiento',
    name: 'homeOrderTracking',
    component: () => import('@/modules/OrderTracking/views/HomeView.vue'),
    meta:
      {
        layout: 'ApplicationLayout',
      },
  },
  {
    path: '',
    name: 'loginOrderTracking',
    component: () => import('@/modules/OrderTracking/views/LoginView.vue'),
  },
];

export default OrderTrackingChildrenRoutes;
