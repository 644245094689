import { RouteRecordRaw } from 'vue-router';

const ClientChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeClient',
    component: () => import('@/modules/Client/views/HomeView.vue'),
  },
  {
    // cambiar path y component al implementar
    path: '/history-trips',
    name: 'HistoryOrdersList',
    component: () => import('@/modules/Client/views/HistoryTripsListView.vue'),
  },
  {
    // cambiar path y component al implementar
    path: '/communication/2',
    name: 'SelectCoordinadorCommunication',
    component: () => import('@/shared/views/CommunicationListView.vue'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/shared/views/MyAccountView.vue'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/shared/views/NotificationsView.vue'),
  },
];

export default ClientChildrenRoutes;
