import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { onMounted, ref } from 'vue';
import DesignationLayout from '@/core/layouts/DesignationLayout.vue';
import SplashView from '@/shared/views/SplashView.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const loadingSplash = ref(true);

onMounted(() => {
  setTimeout(() => {
    loadingSplash.value = false;
  }, 3000);
});


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (loadingSplash.value)
    ? (_openBlock(), _createBlock(SplashView, { key: 0 }))
    : (_openBlock(), _createBlock(DesignationLayout, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}
}

})