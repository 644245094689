import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "body--bold--large text-left" }
const _hoisted_2 = { class: "d-flex justify-space-around" }
const _hoisted_3 = { class: "list-container_header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "list-notification"
}
const _hoisted_6 = { class: "list-notification_container" }
const _hoisted_7 = {
  key: 1,
  class: "list-notification_text"
}
const _hoisted_8 = { class: "list-notification_title body--bold--base" }
const _hoisted_9 = { class: "list-notification_paragraph body--medium--medium" }
const _hoisted_10 = {
  key: 0,
  class: "list-notification_text list-notification-humanCapital"
}
const _hoisted_11 = { class: "list-notification_title body--bold--base" }
const _hoisted_12 = { class: "list-notification_paragraph body--medium--medium" }
const _hoisted_13 = ["onClick", "onKeydown"]
const _hoisted_14 = { class: "list-notification-unread_container" }
const _hoisted_15 = {
  key: 1,
  class: "list-notification-unread_text"
}
const _hoisted_16 = { class: "list-notification-unread_title body--bold--base" }
const _hoisted_17 = {
  key: 0,
  class: "list-notification-unread_text list-notification-humanCapital"
}
const _hoisted_18 = { class: "list-notification-unread_title body--bold--base" }
const _hoisted_19 = {
  key: 1,
  class: "pl-6"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "text-left mb-2 px-5 test-nav__header-nav" }
const _hoisted_23 = { class: "body--medium--large custom-title" }
const _hoisted_24 = { class: "body--medium--medium" }

import {
  onMounted,
  ref,
  watch,
  nextTick,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isUserAuthenticated } from '@/core/guards/AuthGuard';
import { ROLES_NAME, ROLES } from '@/core/constants/role';
import { NotificationApp } from '@/shared/components/NotificationList.vue';
import authService from '@/modules/Auth/services/authService';
import HeaderDrawer from '@/shared/components/HeaderDrawer.vue';
import DetailTrip from '@/shared/components/DetailTrip/DetailTrip.vue';
import { Marker } from '@/core/types/GoogleMap';
import { TripDetail, ResponseTripLocation } from '@/core/types/TripDetail';
import TripsService from '@/shared/services/TripsService';
import ViewDetailIncident from '@/shared/components/ViewDetailIncident.vue';
import ViewDetailHumanCapital from '@/shared/components/ViewDetailHumanCapital.vue';
import IncidentsService from '@/shared/services/IncidentsService';
import NotificationsService from '@/shared/services/NotificationsService';
import { Incident } from '@/core/types/Incidents';
import { openDB } from 'idb';
import { AxiosResponse } from 'axios';
import { API_URL } from '../constants/urls.constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationLayout',
  setup(__props) {

const renderComponent = ref(true);

const router = useRouter();
const route = useRoute();
const drawer = ref(false);
const drawerDetail = ref(false);

const isAuth = isUserAuthenticated();
const shortName = ref('');
const nameUser = ref('');
const role = ref(0);
const dialog = ref(false);
const windowWidth = ref(0);
const heightWindow = ref(0);
const widthDrawer = ref(0);
const titleHeader = ref('');

const tripId = ref(0);
const typeTrip = ref('');
const ordersReady = ref(false);
const selectedTrip = ref<TripDetail>();
// GEOLOCATION
const isLoadingLocation = ref(true);
const isErrorLocation = ref(false);
const isStatusLocation = ref(true);
const isErrorSnackbarLocation = ref(false);
const marker = ref<Marker>();
const saveMarker = ref<Marker>();

const haveIncidents = ref(true);
const haveEvidences = ref(true);

let notificationsCount = 0;

const notifications: NotificationApp[] = [];

const routesOfCoordinator = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeCoordinator',
  },
  {
    title: 'Viajes en curso',
    icon: 'mdi mdi-bus-clock',
    to: 'TripsList',
  },
  {
    title: 'Historial de viajes',
    icon: 'mdi mdi-history',
    to: 'HistoryTripsList',
  },
  {
    title: 'Comunicación',
    icon: 'mdi mdi-forum',
    to: 'SelectCommunication',
  },
];

const routesOfClient = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeClient',
  },
  {
    title: 'Historial de pedidos',
    icon: 'mdi mdi-history',
    to: 'HistoryOrdersList',
  },
  {
    title: 'Comunicación con coordinador',
    icon: 'mdi mdi-phone',
    to: 'SelectCoordinadorCommunication',
  },
];

const routesHumanCapital = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeHumanCapital',
  },
];

const routesOutbound = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'HomeOutbound',
  },
];

const routesOutboundCoordinator = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'HomeOutboundCoordinator',
  },
];

const routesOperationsChief = [
  ...routesOfCoordinator,
  {
    title: 'Gestión de coordinadores',
    icon: 'mdi-account-multiple',
    to: 'CoordinatorsList',
  },
];

if (isAuth) {
  nameUser.value = isAuth.name;
  [shortName.value] = nameUser.value.split(' ');
  role.value = localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 0;
} else if (localStorage.getItem('destinationCode') !== undefined) {
  role.value = 10;
} else {
  router.push({
    path: '/auth/role-selection',
    replace: true,
  });
}

const routesSidebar = ref();
const getRoutes = () => {
  if (role.value !== ROLES[0].id) {
    switch (role.value) {
      case 6: routesSidebar.value = routesOperationsChief; break;
      case 7: routesSidebar.value = routesHumanCapital; break;
      case 8: routesSidebar.value = routesOutbound; break;
      case 9: routesSidebar.value = routesOutboundCoordinator; break;
      default: routesSidebar.value = routesOfCoordinator; break;
    }
  } else {
    routesSidebar.value = routesOfClient;
  }
};

const select = () => {
  switch (route.path) {
    case '/home':
      titleHeader.value = `Hola ${nameUser.value.split(' ')[0]}`;
      break;
    case '/trips':
      titleHeader.value = 'Viajes en curso';
      break;
    case '/history-trips':
      titleHeader.value = 'Historial de viajes';
      break;
    case '/communication/3':
      titleHeader.value = 'Comunicación';
      break;
    case '/communication/1':
      titleHeader.value = 'Comunicación';
      break;
    case '/incidents':
      titleHeader.value = 'Incidentes';
      break;
    case '/detail-trip':
      titleHeader.value = 'Estado de pedido';
      break;
    case '/expired':
      titleHeader.value = 'Estado de pedido';
      break;
    default:
      titleHeader.value = `Hola ${nameUser.value.split(' ')[0]}`;
      break;
  }
};

const forceRerender = async () => {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
};

const updateAllRead = async () => {
  if (notificationsCount > 0) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    const userIdZam = userData.id_zam.toString();
    const db = await openDB(userIdZam, 2);
    const tx = db.transaction('notifications', 'readwrite');
    const store = tx.objectStore('notifications');

    let cursor = await store.openCursor();

    while (cursor) {
      // eslint-disable-next-line
      cursor.value['read'] = true;
      cursor.update(cursor.value);
      // eslint-disable-next-line
      cursor = await cursor.continue();
    }
    await tx.done;

    notifications.forEach((obj) => {
      // eslint-disable-next-line
      obj.read = true;
    });
    notificationsCount = 0;
    // update database
    NotificationsService.markAllAsSeen(Number(userIdZam))
      .catch((error) => {
        console.error(error);
      });
    await tx.done;
    forceRerender();
  }
};

const updateRead = async (key: string) => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const object = await store.get(key);
  // Modificar el objeto
  // eslint-disable-next-line
  object.read = true;
  notifications.find((x) => x.id === key).read = true;
  // Almacenar el objeto modificado
  await store.put(object, key);

  await tx.done;
  if (notificationsCount > 0) {
    notificationsCount -= 1;
  }
  // update database
  NotificationsService.markAsSeen(Number(key))
    .catch((error) => {
      console.error(error);
    });
  await tx.done;
  forceRerender();
};

// const handleServiceWorkerMessage = async (event : any) => {
//   if (event.data.type === 'NEW_NOTIFICATION') {
//     notificationsCount += 1;
//     const notification = {
//       id: notifications.length + 1,
//       type: event.data.data.type,
//       title: event.data.data.title,
//       text: event.data.data.body,
//       link: event.data.data.link,
//       file: event.data.data.file,
//       // eslint-disable-next-line
//       buttonText: (event.data.data.type === 'evidencesTrip') ? 'VER EVIDENCIAS' : (event.data.data.type === 'incident') ? 'VER INCIDENTE' : (event.data.data.type === 'tripReturn') ? 'VER MOTIVO' : (event.data.data.type === 'humanCapital' && event.data.data.link && event.data.data.link != '') ? 'VER MÁS' : '',
//       read: event.data.data.read,
//       tripId: event.data.data.tripId,
//       typeTrip: event.data.data.typeTrip,
//       incident_id: event.data.data.incident_id,
//       date: new Date().toLocaleDateString('es-MX'),
//     };
//     notifications.push(notification);
//     forceRerender();
//   }
// };

const initSSE = (userIdZam: string) => {
  const eventSource = new EventSource(`${API_URL}/v2/notifications/sse/user/${userIdZam}`);
  let incomingData;
  eventSource.onmessage = async (event) => {
    incomingData = JSON.parse(event.data);
    if (incomingData.id_zam.toString() === userIdZam) {
      incomingData.read = false;
      notificationsCount += 1;
      const notification = {
        id: incomingData.notification_id,
        type: incomingData.type,
        title: incomingData.title,
        text: incomingData.body,
        body: incomingData.body,
        link: incomingData.link,
        file: incomingData.file,
        // eslint-disable-next-line
        buttonText: (incomingData.type === 'evidencesTrip') ? 'VER EVIDENCIAS' : (incomingData.type === 'incident') ? 'VER INCIDENTE' : (incomingData.type === 'tripReturn') ? 'VER MOTIVO' : (incomingData.type === 'humanCapital' && incomingData.link && incomingData.link != '') ? 'VER MÁS' : '',
        read: incomingData.read,
        tripId: incomingData.tripId,
        typeTrip: incomingData.typeTrip,
        incident_id: incomingData.incident_id,
        notification_id: incomingData.notification_id,
        date: new Date().toLocaleDateString('es-MX'),
      };
      notifications.push(notification);
      forceRerender();
      // Almacenar en la idb
      const db = await openDB(userIdZam, 2);
      const tx = db.transaction('notifications', 'readwrite');
      const store = tx.objectStore('notifications');
      // Leer y registrar todos los datos en la consola
      await store.put(notification, notification.id);
      await tx.done;
    }
  };
  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    eventSource.close();
    // Reintentar después de un cierto período de tiempo
    setTimeout(() => { initSSE(); }, 60000);
  };
};

const updateNotifications = async () => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  // Notificaciones
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const allData = await store.getAll();
  notificationsCount = 0;
  allData.forEach((obj) => {
    const notification = notifications.find((x) => x.id === obj.id);
    if (obj.read === false) {
      notificationsCount += 1;
    }
    if (notification) {
      notification.read = obj.read;
    }
  });
  await tx.done;
  forceRerender();
};

/* const updateNotificationsCount = async () => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  // Notificaciones
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const allData = await store.getAll();
  notificationsCount = 0;
  allData.forEach((obj) => {
    if (obj.read === false) {
      notificationsCount += 1;
    }
  });
  await tx.done;
  forceRerender();
}; */

onMounted(async () => {
  select();
  heightWindow.value = window.innerHeight;
  windowWidth.value = window.innerWidth;
  widthDrawer.value = heightWindow.value;
  window.addEventListener('resize', () => {
    heightWindow.value = window.innerHeight;
    windowWidth.value = window.innerWidth;
    widthDrawer.value = heightWindow.value;
  });

  watch(route, () => {
    select();
  });

  if (role.value > 0 && role.value <= 6) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    const userIdZam = userData.id_zam.toString();
    // Notificaciones
    const db = await openDB(userIdZam, 2, {
      // eslint-disable-next-line
      upgrade(db) {
        if (!db.objectStoreNames.contains('notifications')) {
          const objectStore = db.createObjectStore('notifications', { autoIncrement: false });
        }
      },
    });
    const tx = db.transaction('notifications', 'readwrite');
    const store = tx.objectStore('notifications');
    const allData = await store.getAll();
    allData.forEach((obj) => {
      if (obj.read === false) {
        notificationsCount += 1;
        const notification = {
          id: obj.notification_id,
          type: obj.type,
          title: obj.title,
          text: obj.body,
          link: obj.link,
          file: obj.file,
          // eslint-disable-next-line
          buttonText: (obj.type === 'evidencesTrip') ? 'VER EVIDENCIA' : (obj.type === 'incident') ? 'VER INCIDENTES' : (obj.type === 'tripReturn') ? 'VER MOTIVO' : (obj.type === 'humanCapital' && obj.link && obj.link != '') ? 'VER MÁS' : '',
          read: obj.read,
          tripId: obj.tripId,
          typeTrip: obj.typeTrip,
          incident_id: obj.incident_id,
          notification_id: obj.notification_id,
          date: new Date().toLocaleDateString('es-MX'),
        };
        notifications.push(notification);
      }
    });
    await tx.done;
    forceRerender();
    initSSE(userIdZam);
  }
  getRoutes();
});

const logout = () => {
  if (role.value < 8 && localStorage.getItem('userData') !== null) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    authService.logout(userData.id_zam);
  }
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('userData');
  // eslint-disable-next-line
  const routeRedirect = role.value === 8 ? '/auth/outbound' : role.value === 9 ? '/auth/outboundCoordinator' : `/auth/login/${role.value}`;
  router.push({
    path: routeRedirect,
    replace: true,
  });
};

const getLocationForTrip = async (id: number, isRefreshing?: boolean) => {
  saveMarker.value = {
    id: marker.value?.id || id,
    position: {
      lat: marker.value?.position.lat || 19.731509,
      lng: marker.value?.position.lng || -99.197767,
    },
    status: isStatusLocation.value,
  };
  isLoadingLocation.value = true;
  isErrorLocation.value = false;
  isErrorSnackbarLocation.value = false;
  marker.value = {
    id,
    position: {
      lat: 19.731509,
      lng: -99.197767,
    },
    status: false,
  };
  await TripsService.getTripLocation(saveMarker.value?.id || id)
    .then((response: AxiosResponse<ResponseTripLocation>) => {
      marker.value = isStatusLocation.value ? {
        id,
        position: {
          lat: 19.731509,
          lng: -99.197767,
        },
        status: false,
      } : {
        id: Number(response.data.tripId),
        position: {
          lat: Number(response.data.lat),
          lng: Number(response.data.lon),
        },
        status: false,
      };
      isErrorLocation.value = false;
    })
    .catch((error: any) => {
      console.log(error);
      if (isRefreshing) {
        isErrorSnackbarLocation.value = true;
        marker.value = saveMarker.value;
      } else {
        isErrorLocation.value = true;
      }
    })
    .finally(() => {
      isLoadingLocation.value = false;
    });
};

const getStatusForTrip = (id: number) => {
  haveIncidents.value = false;
  ordersReady.value = true;
  TripsService.getTripDetail(id)
    .then((response: any) => {
      selectedTrip.value = response.data;
      selectedTrip.value?.cptes.forEach((item) => {
        item.orders?.forEach((order) => {
          if (order.stateOrderDetail.length) {
            haveIncidents.value = order.stateOrderDetail[0]?.incidentes;
          }
        });
      });
      getLocationForTrip(id);
      ordersReady.value = false;
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const setSelectedTrip = (id: number, type: string) => {
  getStatusForTrip(id);
  typeTrip.value = type;
  tripId.value = id;
  drawerDetail.value = true;
};

const showIncidents = ref(false);
const incidentSelected = ref<Incident>();

const setIncident = (incidentId: number) => {
  IncidentsService.getIncidentById(incidentId).then((response) => {
    incidentSelected.value = response.data;
    showIncidents.value = !showIncidents.value;
  }).catch((error) => {
    console.error('ERROR -> GetIncidents', error);
  });
};

const showHumanCapital = ref(false);
let titleHumanCapital = ref<string>();
let descHumanCapital = ref<string>();
let dateHumanCapital = ref<string>();
let linkHumanCapital = ref<string>();
let fileHumanCapital = ref<string>();

const setHumanCapital = (title: string, description: string, date: string, link: string, file: string) => {
  titleHumanCapital = title;
  descHumanCapital = description;
  dateHumanCapital = date;
  linkHumanCapital = link;
  fileHumanCapital = file;
  showHumanCapital.value = !showIncidents.value;
};


return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_P = _resolveComponent("P")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (_openBlock(), _createBlock(_component_v_theme_provider, { theme: "myCustomTheme" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, {
            color: role.value === 8 || role.value === 9 ? 'outbound' : 'primaryHover',
            prominent: ""
          }, {
            default: _withCtx(() => [
              (role.value !== 10)
                ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                    key: 0,
                    variant: "text",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (drawer.value = !drawer.value), ["stop"]))
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(titleHeader.value), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                (role.value !== 5 && role.value !== 4 && role.value !== 9 && role.value !== 7 && role.value !== 8 && role.value !== 10)
                  ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                      activator: _withCtx(({ props }) => [
                        (renderComponent.value)
                          ? (_openBlock(), _createBlock(_component_v_badge, {
                              key: 0,
                              color: "success",
                              content: _unref(notificationsCount),
                              class: "badge-icon",
                              id: "badge-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, _mergeProps({
                                  icon: "mdi mdi-bell",
                                  color: "white"
                                }, props, {
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (updateNotifications())),
                                  onKeydown: _cache[2] || (_cache[2] = ($event: any) => (updateNotifications()))
                                }), null, 16)
                              ]),
                              _: 2
                            }, 1032, ["content"]))
                          : _createCommentVNode("", true)
                      ]),
                      default: _withCtx(() => [
                        (renderComponent.value)
                          ? (_openBlock(), _createBlock(_component_v_list, {
                              key: 0,
                              class: "list-container"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_3, [
                                  _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "body--bold--medium" }, "Notificaciones", -1)),
                                  _createVNode(_component_v_btn, {
                                    variant: "text",
                                    color: "primary",
                                    size: "small",
                                    disabled: !_unref(notificationsCount),
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (updateAllRead())),
                                    onKeydown: _cache[4] || (_cache[4] = ($event: any) => (updateAllRead())),
                                    class: "list-container_headerbtn px-0"
                                  }, {
                                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                                      _createTextVNode("Marcar todo como leído ")
                                    ])),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _createElementVNode("div", null, [
                                  (_unref(notificationsCount) > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.slice().reverse(), (item) => {
                                          return (_openBlock(), _createBlock(_component_v_list_item, {
                                            class: "v-list",
                                            key: item.id
                                          }, {
                                            default: _withCtx(() => [
                                              (item.read)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                                    _createElementVNode("div", _hoisted_6, [
                                                      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "list-notification_point" }, null, -1)),
                                                      _createVNode(_component_v_icon, { icon: "mdi-bell-alert-outline" }),
                                                      (item.type == 'humanCapital')
                                                        ? (_openBlock(), _createBlock(_component_v_chip, {
                                                            key: 0,
                                                            class: "filter-chips__chip",
                                                            variant: "outlined"
                                                          }, {
                                                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                              _createTextVNode(" CAPITAL HUMANO ")
                                                            ])),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.type !== 'humanCapital')
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                            _createElementVNode("h5", _hoisted_8, _toDisplayString(item.title), 1),
                                                            _createElementVNode("p", _hoisted_9, _toDisplayString(item.text), 1),
                                                            (item.buttonText !== '')
                                                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                  key: 0,
                                                                  onClick: ($event: any) => ((item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''),
                                                                  variant: "flat",
                                                                  color: "primary"
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(item.buttonText), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onClick"]))
                                                              : _createCommentVNode("", true)
                                                          ]))
                                                        : _createCommentVNode("", true)
                                                    ]),
                                                    (item.type == 'humanCapital')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                                          _createElementVNode("h5", _hoisted_11, _toDisplayString(item.title), 1),
                                                          _createElementVNode("p", _hoisted_12, _toDisplayString(item.text), 1),
                                                          (item.buttonText !== '')
                                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                key: 0,
                                                                onClick: ($event: any) => ((item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''),
                                                                variant: "flat",
                                                                color: "primary"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(item.buttonText), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["onClick"]))
                                                            : _createCommentVNode("", true)
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : (_openBlock(), _createElementBlock("div", {
                                                    key: 1,
                                                    onClick: ($event: any) => (updateRead(item.id)),
                                                    onKeydown: ($event: any) => (updateRead(item.id)),
                                                    class: "list-notification-unread"
                                                  }, [
                                                    _createElementVNode("div", _hoisted_14, [
                                                      _cache[19] || (_cache[19] = _createElementVNode("span", { class: "list-notification-unread_point" }, null, -1)),
                                                      _createVNode(_component_v_icon, { icon: "mdi-bell-alert-outline" }),
                                                      (item.type == 'humanCapital')
                                                        ? (_openBlock(), _createBlock(_component_v_chip, {
                                                            key: 0,
                                                            class: "filter-chips__chip",
                                                            variant: "outlined"
                                                          }, {
                                                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                              _createTextVNode(" CAPITAL HUMANO ")
                                                            ])),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.type !== 'humanCapital')
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                            _createElementVNode("h5", _hoisted_16, _toDisplayString(item.title), 1),
                                                            _createVNode(_component_P, { class: "list-notification-unread_paragraph body--medium--medium" }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(item.text), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            (item.buttonText !== '')
                                                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                  key: 0,
                                                                  onClick: ($event: any) => ((item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''),
                                                                  variant: "flat",
                                                                  color: "primary"
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(item.buttonText), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onClick"]))
                                                              : _createCommentVNode("", true)
                                                          ]))
                                                        : _createCommentVNode("", true)
                                                    ]),
                                                    (item.type == 'humanCapital')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                          _createElementVNode("h5", _hoisted_18, _toDisplayString(item.title), 1),
                                                          _createVNode(_component_P, { class: "list-notification-unread_paragraph body--medium--medium" }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(item.text), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          (item.buttonText !== '')
                                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                key: 0,
                                                                onClick: ($event: any) => ((item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''),
                                                                variant: "flat",
                                                                color: "primary"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(item.buttonText), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["onClick"]))
                                                            : _createCommentVNode("", true)
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ], 40, _hoisted_13))
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[20] || (_cache[20] = [
                                        _createElementVNode("h5", null, "No hay notificaciones actualmente", -1)
                                      ])))
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              (windowWidth.value > 767)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "mr-3",
                    src: `../../img/icons/${role.value === 8 || role.value === 9 ? 'delta-logo-white' : 'td-logo-blanco'}.svg`,
                    alt: "Logo Transdelta"
                  }, null, 8, _hoisted_20))
                : _createCommentVNode("", true),
              (windowWidth.value <= 767)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    class: "mr-3",
                    src: `../../img/icons/${role.value === 8 || role.value === 9 ? 'delta-icon-white' : 'td-icon-withe'}.svg`,
                    alt: "Icon Transdelta"
                  }, null, 8, _hoisted_21))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["color"]),
          _createVNode(_component_v_navigation_drawer, {
            modelValue: drawer.value,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((drawer).value = $event)),
            temporary: "",
            app: "",
            class: "test-nav",
            scrim: false,
            elevation: "24"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(nameUser.value || 'Usuario'), 1),
                _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(ROLES_NAME)[role.value - 1] || 'Rol'), 1),
                _createVNode(_component_v_btn, {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (drawer.value = !drawer.value)),
                  class: "float-button test-nav__header-nav__custom-button",
                  density: "compact",
                  icon: "mdi-arrow-left-drop-circle-outline"
                })
              ]),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, {
                class: "mt-1 text-left pl-0 d-flex flex-column nav-list-items",
                height: "70vh"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routesSidebar.value, (item, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: index,
                        class: "menu-items body--medium--medium",
                        "prepend-icon": item.icon
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: item.to }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1032, ["prepend-icon"]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_v_list_item, {
                      class: "menu-items body--medium--medium",
                      "prepend-icon": "mdi mdi-account"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, { to: "/my-account" }, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Mi cuenta")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (role.value !== 5 && role.value !== 4 && role.value !== 7 && role.value !== 8 && role.value !== 9)
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 0,
                          class: "menu-items body--medium--medium",
                          "prepend-icon": "mdi mdi-bell-ring"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, { to: "/notifications" }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("Notificaciones")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_dialog, {
                      modelValue: dialog.value,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((dialog).value = $event)),
                      persistent: "",
                      width: "400"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_list_item, _mergeProps({ class: "nav-icon menu-items body--medium--medium" }, props, { "prepend-icon": "mdi mdi-logout-variant" }), {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createElementVNode("p", { class: "btn-logout" }, "Salir", -1)
                          ])),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, { class: "logout-title" }, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createElementVNode("h4", null, "Estás por cerrar sesión", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, { class: "logout-description" }, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createElementVNode("p", { class: "body--light--large" }, "¿Estás seguro que deseas salir?", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_actions, { class: "logout-btn" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: role.value === 8 || role.value === 9 ? 'outbound' : 'primary',
                                  class: "mr-4 body--medium--medium",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (dialog.value = false)),
                                  variant: "outlined"
                                }, {
                                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                                    _createTextVNode("Cancelar ")
                                  ])),
                                  _: 1
                                }, 8, ["color"]),
                                _createVNode(_component_v_btn, {
                                  color: role.value === 8 || role.value === 9 ? 'outbound' : 'primary',
                                  variant: "flat",
                                  class: "btn-action body--medium--medium",
                                  onClick: logout
                                }, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode(" Salir")
                                  ])),
                                  _: 1
                                }, 8, ["color"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_navigation_drawer, {
            modelValue: drawerDetail.value,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((drawerDetail).value = $event)),
            temporary: "",
            location: "bottom",
            scrim: false,
            touchless: "",
            width: widthDrawer.value - 64,
            class: "detail-trip-container"
          }, {
            default: _withCtx(() => [
              _createVNode(HeaderDrawer, {
                "on-click": () => drawerDetail.value = !drawerDetail.value
              }, null, 8, ["on-click"]),
              _createVNode(DetailTrip, {
                loadingDetail: ordersReady.value,
                trip: selectedTrip.value,
                marker: marker.value,
                typeTrip: typeTrip.value,
                onFindLocation: _cache[9] || (_cache[9] = (idTrip) => getLocationForTrip(idTrip)),
                onFindLocationRefreshing: _cache[10] || (_cache[10] = (idTrip) => getLocationForTrip(idTrip, true)),
                isErrorLocation: isErrorLocation.value,
                isLoadingLocation: isLoadingLocation.value,
                haveIncidents: haveIncidents.value,
                haveEvidences: haveEvidences.value
              }, null, 8, ["loadingDetail", "trip", "marker", "typeTrip", "isErrorLocation", "isLoadingLocation", "haveIncidents", "haveEvidences"])
            ]),
            _: 1
          }, 8, ["modelValue", "width"]),
          _createVNode(_component_v_dialog, {
            modelValue: showIncidents.value,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((showIncidents).value = $event)),
            persistent: "",
            width: "600"
          }, {
            default: _withCtx(() => [
              _createVNode(ViewDetailIncident, {
                incident: incidentSelected.value,
                "on-click": () => showIncidents.value = false
              }, null, 8, ["incident", "on-click"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_dialog, {
            modelValue: showHumanCapital.value,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((showHumanCapital).value = $event)),
            persistent: "",
            width: "600"
          }, {
            default: _withCtx(() => [
              _createVNode(ViewDetailHumanCapital, {
                title: _unref(titleHumanCapital),
                description: _unref(descHumanCapital),
                date: _unref(dateHumanCapital),
                link: _unref(linkHumanCapital),
                file: _unref(fileHumanCapital),
                "on-click": () => showHumanCapital.value = false
              }, null, 8, ["title", "description", "date", "link", "file", "on-click"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})