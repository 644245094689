import { RouteRecordRaw } from 'vue-router';

const ClientDestinationChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/opinion-poll',
    name: 'OpinionPoll',
    component: () => import('@/modules/ClientDestination/views/OpinionPollView.vue'),
  },
  {
    path: '/detail-trip',
    name: 'DetailTrip',
    component: () => import('@/modules/ClientDestination/views/DetailTripView.vue'),
  },
  {
    path: '/expired',
    name: 'Expired',
    component: () => import('@/modules/ClientDestination/views/ExpiredView.vue'),
  },
  {
    path: '/rescheduled',
    name: 'Rescheduled',
    component: () => import('@/modules/ClientDestination/views/RescheduledView.vue'),
  },
  {
    path: '/return',
    name: 'Return',
    component: () => import('@/modules/ClientDestination/views/ReturnView.vue'),
  },
];

export default ClientDestinationChildrenRoutes;
