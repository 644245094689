import { RouteRecordRaw } from 'vue-router';

const OutboundCoordinatorChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'HomeOutboundCoordinator',
    component: () => import('@/modules/OutboundCoordinator/views/HomeView.vue'),
  },
  {
    path: '/trip-detail/:tripId',
    name: 'TripDetail',
    component: () => import('@/modules/OutboundCoordinator/views/TripDetailView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/modules/OutboundCoordinator/views/RegisterView.vue'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/shared/views/MyAccountView.vue'),
  },
];

export default OutboundCoordinatorChildrenRoutes;
