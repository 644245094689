import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_2 = { class: "container-icon" }
const _hoisted_3 = { class: "body--bold--medium color-text" }
const _hoisted_4 = { class: "d-flex justify-space-between pt-3" }
const _hoisted_5 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_6 = { class: "container-icon" }
const _hoisted_7 = { class: "body--medium--small color-text" }
const _hoisted_8 = { class: "d-flex justify-start align-center mb-3" }
const _hoisted_9 = { class: "container-icon" }
const _hoisted_10 = { class: "color-text" }
const _hoisted_11 = { class: "mt-2 d-flex justify-start align-start" }
const _hoisted_12 = { class: "d-flex flex-column font-description" }
const _hoisted_13 = { class: "body--medium--small color-text" }
const _hoisted_14 = { class: "body--medium--small color-text" }


import { TripDetail } from '@/core/types/TripDetail';


export default /*@__PURE__*/_defineComponent({
  __name: 'DirectTrip',
  props: {
    trip: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_icon, { size: "18" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("mdi-assistant")
          ])),
          _: 1
        })
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Cliente", -1))
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(props.trip?.cptes[0].clientZam.name || '-'), 1),
    _createVNode(_component_v_divider, { class: "my-5" }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_v_icon, { size: "18" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("mdi-map-marker")
                      ])),
                      _: 1
                    })
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Origen", -1))
                ]),
                _createElementVNode("p", _hoisted_7, " Dirección: " + _toDisplayString(props.trip?.cptes[0].orders[0].origin.address || '-'), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_v_icon, { size: "18" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("mdi-flag")
                      ])),
                      _: 1
                    })
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "ml-2 body--bold--medium color-text" }, "Destinos", -1))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("p", _hoisted_13, " Destinatario: " + _toDisplayString(props.trip?.cptes[0].orders[0].destination.name || '-'), 1),
                      _createElementVNode("p", _hoisted_14, " Dirección: " + _toDisplayString(props.trip?.cptes[0].orders[0].destination.address || '-'), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})