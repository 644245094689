import { RouteRecordRaw } from 'vue-router';

const HumanCapitalChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeHumanCapital',
    component: () => import('@/modules/HumanCapital/views/EventLoadingView.vue'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/shared/views/MyAccountView.vue'),
  },
];

export default HumanCapitalChildrenRoutes;
