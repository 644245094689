<script setup lang='ts'>
import {
  defineProps,
} from 'vue';
import { TripDetail } from '@/core/types/TripDetail';

const props = defineProps<{
  trip?: TripDetail,
}>();
</script>

<template>
  <div class='d-flex justify-space-between pt-3'>
    <v-row>
      <v-col cols='12'>
        <div>
          <div class='d-flex justify-start align-center mb-3'>
            <div class='container-icon'>
              <v-icon size='18'>mdi-assistant</v-icon>
            </div>
            <p class='ml-2 body--bold--medium color-text'>Cliente</p>
          </div>
          <p class='body--medium--small color-text'>
            {{ props.trip?.cptes[0]?.clientZam?.name || '-' }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
  <v-divider class='my-5'></v-divider>
  <div>
    <v-row>
      <v-col cols='12' md='6'>
        <div>
          <div class='d-flex justify-start align-center mb-3'>
            <div class='container-icon'>
              <v-icon size='18'>mdi-map-marker</v-icon>
            </div>
            <p class='ml-2 body--bold--medium color-text '>Origen</p>
          </div>
          <div class='color-text'>
            <div class='mt-2 d-flex justify-start align-start'>
              <div class='d-flex flex-column font-description'>
                <p class='body--medium--small color-text'> Dirección:
                  {{ props.trip?.cptes[0]?.orders[0]?.origin?.address || '-' }} </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols='12' md='6'>
        <div class='d-flex justify-start align-center mb-3'>
          <div class='container-icon'>
            <v-icon size='18'>mdi-flag</v-icon>
          </div>
          <p class='ml-2 body--bold--medium color-text '>Destinos</p>
        </div>
        <template v-for='(item, index) in props.trip?.cptes[0].orders' :key='index'>
          <div class='my-2'>
            <div class='d-flex justify-start'>
              <v-icon size='14' class='color-text'>mdi-flag</v-icon>
              <p class='body--medium--small color-text' style='padding-left: 10px'>
                Destinatario:
                {{ item?.destination?.name || '-' }} </p>
            </div>
            <p class='body--medium--small color-text' style='padding-left: 24px'>
              Dirección:
              {{ item?.destination?.address || '-' }} </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang='scss'>
@import '../../../../../scss/variable';

.color-text {
  color: $secondaryLight !important;
}

.container-icon {
  background-color: $primaryTransparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryHover;
}
</style>
