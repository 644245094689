import { RouteRecordRaw } from 'vue-router';

const OutboundChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'HomeOutbound',
    component: () => import('@/modules/Outbound/views/HomeView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/modules/Outbound/views/RegisterView.vue'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/shared/views/MyAccountView.vue'),
  },
  {
    path: '/new-order',
    name: 'NewOrder',
    component: () => import('@/modules/Outbound/views/NewOrder.vue'),
  },
];

export default OutboundChildrenRoutes;
