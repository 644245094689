<script setup lang='ts'>

import { defineProps, ref } from 'vue';

import CardIncident from '@/shared/components/CardIncident.vue';
import ViewDetailIncident from '@/shared/components/ViewDetailIncident.vue';
import { Incident } from '@/core/types/Incidents';

const props = defineProps<{
  haveIncidents: boolean,
  incidents: Incident[]
}>();

const showIncidents = ref(false);
const incidentSelected = ref<Incident>();

const setIncidents = (index: number) => {
  if (props.incidents[index]) {
    incidentSelected.value = props.incidents[index];
    showIncidents.value = !showIncidents.value;
  }
};

</script>

<template>
  <div class='d-flex justify-start align-center mt-4'>
    <div class='container-icon'>
      <v-icon size='18'>mdi-bus-alert</v-icon>
    </div>
    <p class='ml-2 body--bold--medium color-text'>Incidentes</p>
  </div>
  <div v-if='props.haveIncidents'>
    <div v-if='!props.incidents' class='d-flex justify-center'>
      <v-progress-circular style='margin-top: 200px' color='primary' indeterminate
                           :size='50'
                           :width='8'></v-progress-circular>
    </div>
    <div v-if='props.incidents' class='mt-4 d-flex justify-start align-start flex-wrap'>
      <div class='cards-custom' v-for='(item, index) in props.incidents'
           :key='index'>
        <CardIncident :incident='item' :onClick='() => setIncidents(index)' />
      </div>
    </div>
  </div>
  <p v-if='!props.haveIncidents'
     class='body--medium--small text-disabled mt-4 text-left color-text-disabled'>
    No hay incidentes
  </p>
  <v-dialog v-model='showIncidents' persistent width='600'>
    <ViewDetailIncident
      :incident='incidentSelected'
      :on-click='() => showIncidents = false' />
  </v-dialog>
</template>

<style scoped lang='scss'>
@import '../../../../scss/variable';

.container-icon {
  background-color: $primaryTransparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryHover;
}

.cards-custom {
  width: 100%;
  max-width: 17.125rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.color-text {
  color: $secondaryLight !important;
}

.color-text-disabled {
  color: $textDark !important;
}
</style>
