<script setup lang='ts'>
import {
  defineProps,
} from 'vue';
import Evidence from '@/core/types/Evidences';

const props = defineProps<{
  haveEvidences: boolean,
  evidences?: Evidence[]
}>();
</script>

<template>
  <div class='d-flex justify-start align-center mt-4'>
    <div class='container-icon'>
      <v-icon size='18'>mdi-archive-arrow-down-outline</v-icon>
    </div>
    <p class='ml-2 body--bold--medium color-text'>Evidencias</p>
  </div>
  <div v-if='props.haveEvidences'>
    <div v-if='!props.evidences' class='d-flex justify-center'>
      <v-progress-circular style='margin-top: 200px' color='primary' indeterminate
                           :size='50'
                           :width='8'></v-progress-circular>
    </div>
    <div class='mt-4 d-flex flex-wrap' v-if='props.evidences && props.evidences.length > 0'>
      <a class='card-evidences' :href='item.url' target='_blank'
         v-for='(item, index) in props.evidences'
         :key='index'>
        <img class='card-evidences__img' :src="require('@/assets/iconsApp/pdf.svg')"
             alt='icon PDF'>
        <p class='card-evidences__text'>Ver PDF completo</p>
      </a>
    </div>
  </div>
  <p v-if='!props.haveEvidences || !props.evidences || props.evidences.length == 0' class='body--medium--small text-disabled mt-4 text-left color-text-disabled'>
    No hay evidencias subidas
  </p>
</template>

<style scoped lang='scss'>
@import '../../../../scss/variable';

.container-icon {
  background-color: $primaryTransparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryHover;
}

.card-evidences {
  text-decoration: none;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  width: 8.75rem !important;
  border: 1px solid $primary;
  cursor: pointer;
  background-color: $primaryTransparent;
  padding: 0.5rem 0;

  &__img {
    height: 4.5rem;
  }

  &__text {
    color: $primary;
    font-size: 0.8rem;
    font-family: Gotham-Medium, sans-serif;
    font-weight: 500;
    padding: 0.5rem;
  }
}

.color-text {
  color: $secondaryLight !important;
}

.color-text-disabled {
  color: $textDark !important;
}
</style>
