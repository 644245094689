/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_URL } from '@/core/constants/urls.constants';
import AxiosClient from '@/core/api/api.client';
import { ParamsForTrips, TripsWithPagination } from '@/core/types/TripsWithPagination';
import Status from '@/core/types/Status';
import { Order } from '@/core/types/Orders';
import { HistoryTripsWithPagination } from '@/core/types/HistoryTripsWithPagination';
import { EndTrip } from '@/core/types/EndTrip';
import { PollResponse, ResponseTripLocation, TripDetail } from '@/core/types/TripDetail';

let abortController: any;

const TripsService = {
  getTrips: async (params: ParamsForTrips) => {
    if (params.abortPreviewRequest && abortController) {
      abortController.abort();
      abortController.signal.onabort = () => {
        console.log('aborted');
      };
    }

    abortController = new AbortController();

    const paramsToSend = {
      page: params.page,
      size: params.size,
      orderCriteria: params.orderCriteria,
      orderField: params.orderField,
      finder: params.finder,
      orderStatus: params.status,
      fleet: params.fleet,
      dateTo: params.dateTo,
      dateFrom: params.dateFrom,
    };
    if (paramsToSend.dateTo === '') delete paramsToSend.dateTo;
    if (paramsToSend.dateFrom === '') delete paramsToSend.dateFrom;
    if (paramsToSend.finder === '') delete paramsToSend.finder;
    if (paramsToSend.orderStatus === '') delete paramsToSend.orderStatus;
    if (paramsToSend.fleet === '') delete paramsToSend.fleet;
    if (paramsToSend.orderCriteria === '') delete paramsToSend.orderCriteria;
    if (paramsToSend.orderField === '') delete paramsToSend.orderField;
    return AxiosClient.get<TripsWithPagination>(`${API_URL}/v2/trips`, {
      params: paramsToSend,
      signal: abortController.signal,
    });
  },
  getStatus: async () => AxiosClient.get<Status[]>(`${API_URL}/v2/status`),
  getClientStatus: async () => AxiosClient.get<Status[]>(`${API_URL}/v2/status/client`),
  getStatusForOrder: async (orderNumber: number) => AxiosClient.get<Order>(`${API_URL}/orders/${orderNumber}/state/coordinator`),
  getHistoryTripsCoordinator: async (params: ParamsForTrips) => {
    if (params.abortPreviewRequest && abortController) {
      abortController.abort();
      abortController.signal.onabort = () => {
        console.log('aborted');
      };
    }

    abortController = new AbortController();

    const paramsToSend = {
      page: params.page,
      size: params.size,
      orderCriteria: params.orderCriteria,
      orderField: params.orderField,
      finder: params.finder,
      dateTo: params.dateTo,
      dateFrom: params.dateFrom,
    };
    if (paramsToSend.finder === '') delete paramsToSend.finder;
    if (paramsToSend.dateTo === '') delete paramsToSend.dateTo;
    if (paramsToSend.dateFrom === '') delete paramsToSend.dateFrom;
    if (paramsToSend.orderCriteria === '') delete paramsToSend.orderCriteria;
    if (paramsToSend.orderField === '') delete paramsToSend.orderField;

    return AxiosClient.get<HistoryTripsWithPagination>(`${API_URL}/v2/trips/coordinator/end`, {
      params: paramsToSend,
      signal: abortController.signal,
    });
  },
  getTripDetail: async (tripId: number) => AxiosClient.get<TripDetail>(`${API_URL}/v2/trips/${tripId}/detail`),
  getTripLocation: async (tripId: number) => AxiosClient.get<ResponseTripLocation>(`${API_URL}/trips/${tripId}/geolocation`),
  finishTrip: async (tripsId: number[], endingComment:string) => AxiosClient.put(`${API_URL}/trips/finalize`, { id: tripsId, reason: endingComment }),
  getEndTrip: async (tripId: number) => AxiosClient.get<EndTrip>(`${API_URL}/v2/trips/${tripId}/coordinator/end`),
  getPollResponse: async (orderId: any) => AxiosClient.get<PollResponse[]>(`${API_URL}/v2/poll/answers/${orderId}`),
  downloadTrips: async (params: ParamsForTrips) => {
    const paramsToSend = {
      orderCriteria: params.orderCriteria,
      orderField: params.orderField,
      finder: params.finder,
      orderStatus: params.status,
      fleet: params.fleet,
      dateTo: params.dateTo,
      dateFrom: params.dateFrom,
    };
    if (paramsToSend.dateTo === '') delete paramsToSend.dateTo;
    if (paramsToSend.dateFrom === '') delete paramsToSend.dateFrom;
    if (paramsToSend.finder === '') delete paramsToSend.finder;
    if (paramsToSend.orderStatus === '') delete paramsToSend.orderStatus;
    if (paramsToSend.fleet === '') delete paramsToSend.fleet;
    if (paramsToSend.orderCriteria === '') delete paramsToSend.orderCriteria;
    if (paramsToSend.orderField === '') delete paramsToSend.orderField;
    return AxiosClient.get(`${API_URL}/v2/trips/export`, {
      params: paramsToSend,
      responseType: 'blob',
    });
  },
  downloadHistoryTrips: async (params: ParamsForTrips) => {
    const paramsToSend = {
      page: params.page,
      size: params.size,
      orderCriteria: params.orderCriteria,
      orderField: params.orderField,
      finder: params.finder,
      dateTo: params.dateTo,
      dateFrom: params.dateFrom,
    };
    if (paramsToSend.finder === '') delete paramsToSend.finder;
    if (paramsToSend.dateTo === '') delete paramsToSend.dateTo;
    if (paramsToSend.dateFrom === '') delete paramsToSend.dateFrom;
    if (paramsToSend.orderCriteria === '') delete paramsToSend.orderCriteria;
    if (paramsToSend.orderField === '') delete paramsToSend.orderField;

    return AxiosClient.get(`${API_URL}/v2/trips/coordinator/end/export`, {
      params: paramsToSend,
      responseType: 'blob',
    });
  },
};

export default TripsService;
