<script setup lang='ts'>
import { ref } from 'vue';
import NotificationsService from '@/shared/services/NotificationsService';

defineProps<{
  title: string,
  date: string,
  description: string,
  link?: string,
  file?: string,
  onClick:() => void
}>();

const show = ref(false);
const isDownloadSuccess = ref(false);

const downloadFile = (file: string) => {
  try {
    NotificationsService.getSignedUrl(file)
      .then((response: AxiosResponse<string>) => {
        const url = response.data;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = url;
        link.click();
        isDownloadSuccess.value = true;
      })
      .catch((error) => {
        console.log(error);
        isDownloadSuccess.value = false;
      })
      .finally(() => {
        isDownloadSuccess.value = true;
      });
  } catch (error) {
    console.log(error);
    isDownloadSuccess.value = false;
  }
};

</script>

<template>
  <v-card class='mt-10'>
    <v-card-title class='body--bold--xlarge color-text'>Notificación</v-card-title>
    <v-chip class='filter-chips__chip mt-2 mb-5 ml-3' variant='outlined'> CAPITAL HUMANO </v-chip>
    <div class='px-5'>
      <p class='body--medium--xlarge color-text'>{{title}}</p>
      <p class='body--medium--small' style='color: #857c7c;font-weight:100 !important;'>{{date}}</p>
    </div>
    <div class='px-5 mt-3 mb-3'>
      <p class='body--medium--medium' style='font-family: inherit !important;'>{{description}}</p>
    </div>
    <div v-if='link && link !== ""' class='px-5 my-5'>
      <p class='body--medium--medium color-text mb-2'>Link compartido</p>
      <a class='body--light--large color-text' :href='link' target='_blank' style='color: rgb(var(--v-theme-primary)) !important;text-decoration: none;'>{{link || '-'}}</a>
    </div>
    <div v-if='file && file !== ""' class='px-5'>
      <p class='body--medium--medium color-text mb-2'>Documento Adjunto</p>
      <v-btn class='body--medium--medium' color='primary' variant='elevated' @click='downloadFile(file)'>Descargar <i class='mdi mdi-download' style='margin-left: 0.5rem;font-size: 1.2rem;'></i></v-btn>
    </div>
    <v-card-actions class='d-flex justify-end my-2 pr-5'>
      <v-btn class='body--medium--medium' color='primary' variant='elevated' @click='onClick'>Cerrar</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model='expandImg'>
    <div class='img-visualizer overflow-auto'>
      <v-btn class='button-close' color='white' @click='expandImg=false' variant='text' icon>
        <v-icon size='40'>mdi-close</v-icon>
      </v-btn>
      <div class='justify-center d-flex px-10 py-5 color-text' style='margin-top: 50px'>
        <v-progress-circular v-show='!show' style='margin-top: 100px; margin-bottom: 100px;' color='primary' indeterminate :size='50' :width='8'></v-progress-circular>
        <img class='img-view' v-show="show" @load="show=true" :src='incident?.image' alt='incidente'>
      </div>
    </div>
  </v-dialog>
  <v-snackbar
    :timeout='2000'
    :color="isDownloadSuccess ? 'success': 'danger'"
    elevation='24'
    :model-value='isDownloadSuccess'
  >
  <span
    :class="['mdi',{ 'mdi-check-circle-outline' : isDownloadSuccess, 'mdi-information-outline' : !isDownloadSuccess }]"></span>
    {{ isDownloadSuccess ? 'El documento se descargó exitosamente' : 'Ocurrio un error al descargar el documento, intentelo nuevamente.'
    }}
  </v-snackbar>
</template>

<style scoped lang='scss'>
.v-chip{
  width: 10rem;
  min-width: fit-content;
  overflow: hidden;
  color: rgb(var(--v-theme-primary)) !important;
}

.button-close {
  position: absolute; top: 50px; right: 20px;
}

img {
  cursor: pointer;
  width: 80%;
  height: auto;
}

.img-view {
  width: 80%;
  height: auto;
}

.color-text {
  color: $secondaryLight !important;
}

// RESPONSIVE 390PX

@media only screen and (max-width: 390px) {
  .button-close {
    position: absolute; top: 20px; right: 10px;
  }
  .img-visualizer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-view {
    width: 100%;
    height: auto;
  }
}
</style>
