<script setup lang='ts'>
import {
  defineProps,
} from 'vue';
import { TripDetail } from '@/core/types/TripDetail';

const props = defineProps<{
  trip?: TripDetail,
}>();
</script>

<template>
  <template v-for='(item, index) in props.trip?.cptes' :key='index'>
    <div>
      <v-row>
        <v-col cols='12' md='6'>
          <div>
            <div class='d-flex justify-start align-center mb-3'>
              <div class='container-icon'>
                <v-icon size='18'>mdi-assistant</v-icon>
              </div>
              <p class='ml-2 body--bold--medium color-text'>Cliente {{ index + 1 }}</p>
            </div>
            <p class='body--medium--small color-text'>{{ item.clientZam.name || '-'
              }}</p>
          </div>
        </v-col>
        <v-col cols='12' md='6'>
          <div>
            <div class='d-flex justify-start align-center mb-3'>
              <div class='container-icon'>
                <v-icon size='18'>mdi-map-marker-account</v-icon>
              </div>
              <p class='ml-2 body--bold--medium color-text'>N° de PF</p>
            </div>
            <div class='color-text'>
              <div class='mt-2 d-flex justify-start align-start'>
                <div class='d-flex flex-column font-description'>
                  <p class='body--medium--small'> Dirección:
                    {{ item.id_cp || '-' }} </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider class='my-5'></v-divider>
    <div>
      <v-row>
        <v-col cols='12' md='6'>
          <div>
            <div class='d-flex justify-start align-center mb-3'>
              <div class='container-icon'>
                <v-icon size='18'>mdi-map-marker</v-icon>
              </div>
              <p class='ml-2 body--bold--medium color-text'>Origen</p>
            </div>
            <div class='color-text'>
              <div class='mt-2 d-flex justify-start align-start'>
                <div class='d-flex flex-column font-description'>
                  <p class='body--medium--small color-text'> Dirección:
                    {{ item.orders[0].origin.address || '-' }} </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols='12' md='6'>
          <div>
            <div class='d-flex justify-start align-center mb-3'>
              <div class='container-icon'>
                <v-icon size='18'>mdi-flag</v-icon>
              </div>
              <p class='ml-2 body--bold--medium color-text'>Destinos</p>
            </div>
            <p class='body--medium--small color-text'> Destinatario:
              {{ item.orders[0].destination.name || '-' }} </p>
            <p class='body--medium--small color-text'> Dirección:
              {{ item.orders[0].destination.address || '-' }} </p>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if='index < trip?.cptes.length - 1' class='my-5'></v-divider>
    </div>
  </template>
</template>

<style scoped lang='scss'>
@import '../../../../../scss/variable';

.color-text {
  color: $secondaryLight !important;
}

.container-icon {
  background-color: $primaryTransparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryHover;
}
</style>
