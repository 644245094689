import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-icon" }
const _hoisted_2 = { class: "body--bold--medium color-text" }
const _hoisted_3 = { class: "body--medium--small color-text-light" }

import { Incident } from '@/core/types/Incidents';


export default /*@__PURE__*/_defineComponent({
  __name: 'CardIncident',
  props: {
    incident: {},
    onClick: { type: Function }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    width: "100%",
    class: "incident-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, { size: "18" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("mdi-sign-caution")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.incident.origin) + " - " + _toDisplayString(_ctx.incident.destination), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString((_ctx.incident.date_incident.split(' ')[0]).split('-').reverse().join('/')), 1),
      _createVNode(_component_v_btn, {
        color: "primary",
        class: "text-capitalize mt-5",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Ver detalles")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}
}

})