<script setup lang='ts'>
import {
  defineProps,
} from 'vue';
import { TripDetail } from '@/core/types/TripDetail';

const props = defineProps<{
  trip?: TripDetail,
}>();
</script>

<template>
  <div class='d-flex justify-start align-center mb-3'>
    <div class='container-icon'>
      <v-icon size='18'>mdi-assistant</v-icon>
    </div>
    <p class='ml-2 body--bold--medium color-text '>Cliente</p>
  </div>
  <p class='body--bold--medium color-text'>{{ props.trip?.cptes[0].clientZam.name || '-' }}</p>
  <v-divider class='my-5'></v-divider>
  <div class='d-flex justify-space-between pt-3'>
    <v-row>
      <v-col cols='12' md='6'>
        <div>
          <div class='d-flex justify-start align-center mb-3'>
            <div class='container-icon'>
              <v-icon size='18'>mdi-map-marker</v-icon>
            </div>
            <p class='ml-2 body--bold--medium color-text'>Origen</p>
          </div>
          <p class='body--medium--small color-text'> Dirección:
            {{ props.trip?.cptes[0].orders[0].origin.address || '-' }} </p>
        </div>
      </v-col>
      <v-col cols='12' md='6'>
        <div>
          <div class='d-flex justify-start align-center mb-3'>
            <div class='container-icon'>
              <v-icon size='18'>mdi-flag</v-icon>
            </div>
            <p class='ml-2 body--bold--medium color-text'>Destinos</p>
          </div>
          <div class='color-text'>
            <div class='mt-2 d-flex justify-start align-start'>
              <div class='d-flex flex-column font-description'>
                <p class='body--medium--small color-text'> Destinatario:
                  {{ props.trip?.cptes[0].orders[0].destination.name || '-' }} </p>
                <p class='body--medium--small color-text'> Dirección:
                  {{ props.trip?.cptes[0].orders[0].destination.address || '-' }} </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang='scss'>
@import '../../../../../scss/variable';

.color-text {
  color: $secondaryLight !important;
}
</style>
