import { RouteRecordRaw } from 'vue-router';

const ManagerChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeManager',
    component: () => import('@/modules/Coordinator/views/HomeView.vue'),
  },
];

export default ManagerChildrenRoutes;
