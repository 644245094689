import { RouteRecordRaw } from 'vue-router';

const getRoleId = () => {
  const role = localStorage.getItem('role');
  return role ? JSON.parse(role) : null;
};

const roleRoutes = {
  1: 'client',
  2: 'coordinator',
  4: 'monitoringChief',
  5: 'manager',
  6: 'operationsChief',
  7: 'humanCapital',
  8: 'outbound',
  9: 'outboundCoordinator',
  10: 'clientDestination',
};

const AuthChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: 'login/:role',
    name: 'login',
    component: () => import('@/modules/Auth/views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'register/:role',
    name: 'register',
    component: () => import('@/modules/Auth/views/RegisterView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'role-selection',
    name: 'role-selection',
    component: () => import('@/modules/Auth/views/RoleSelectionView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'recover-password/:role',
    name: 'recover-password',
    component: () => import('@/modules/Auth/views/RecoveryView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'outbound',
    name: 'Outbound',
    component: () => import('@/modules/Outbound/views/LoginView.vue'),
  },
  {
    path: 'outbound/register',
    name: 'outbound-register',
    component: () => import('@/modules/Outbound/views/RegisterView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: '/auth/recover-password/outbound',
    name: 'outbound-recover-password',
    component: () => import('@/modules/Outbound/views/RecoveryView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'outboundCoordinator',
    name: 'OutboundCoordinator',
    component: () => import('@/modules/OutboundCoordinator/views/LoginView.vue'),
  },
  {
    path: 'outboundCoordinator/register',
    name: 'outboundCoordinator-register',
    component: () => import('@/modules/OutboundCoordinator/views/RegisterView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: '/auth/recover-password/outboundCoordinator',
    name: 'outboundCoordinator-recover-password',
    component: () => import('@/modules/OutboundCoordinator/views/RecoveryView.vue'),
    beforeEnter: (to, from, next) => {
      const roleId = getRoleId();
      if (roleId) {
        const role = roleRoutes[roleId as keyof typeof roleRoutes];
        next({ name: role });
      } else {
        next();
      }
    },
  },
  {
    path: 'tracking',
    name: 'LoginDestination',
    component: () => import('@/modules/ClientDestination/views/LoginDestinationView.vue'),
  },
];

export default AuthChildrenRoutes;
